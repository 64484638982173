<form #form [formGroup]="this.pinForm" (submit)="parentComponent.submit($event)" (paste)="paste($event)">
  <div class="mt-8 w-full flex gap-x-1" formArrayName="pass">
    @for (i of Array(6); track $index) {
        <input
          type="number"
          maxlength="1"
          autocomplete="off"
          name="pass[]"
          (keyup)="this.jumpNext($event, $index)"
          id="passcode-input-{{$index}}"
          formControlName="{{ $index }}"
          class="passcode-input inline-flex text-3xl h-16 w-12 text-center border border-gray-light
                                            text-gray-dark font-bold rounded-xl focus:!outline-none focus:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]
                                            disabled:bg-white disabled:border-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        />
    }
  </div>
  <button type="submit" class="invisible"></button>
</form>
