<app-theming>
  <div id="page-wrapper" class="relative min-h-screen flex flex-col items-center">
    <div class="w-[54rem] max-w-full">
      <header class="w-72 mx-auto bg-transparent">
        <div class="bg-black flex justify-center items-end pb-4 pt-14 rounded-b-3xl">
          <a href="/" class="block">
            <img class="h-7" src="/assets/images/logo_klub.png" alt="Klub Quiosque" />
          </a>
        </div>
      </header>
      <div class="flex flex-col">
        <div class="mt-16 mb-8 text-center z-10 mx-auto">
          <h2 class="text-2xl font-bold text-white mb-3">Witaj w klubie Quiosque</h2>
          <h2 class="text-white font-light">załóż konto i korzystaj z rabatów<br /> od wszystkich naszych marek</h2>
        </div>

        <div class="relative mx-6">
          <div class="bottom-20 absolute left-1/2 h-[364px] -translate-x-1/2 w-full border-[3px] border-white rounded-2xl flex items-end justify-center">
          </div>
          <app-slider [slidesPerPage]="1" [slidePxWidth]="364" [slidePxGap]="1" activePageBulletClass="bg-white">
            @for (slide of slides; track $index) {
              <app-slide [onEnterScreenCallbackArguments]="[slide.brand]" (onEnterScreen)="changeTheme($event)">
                <div class="mb-9">
                  <img class="mx-auto" [src]="slide.logo" [alt]="slide.name" />
                </div>
                <div class="relative w-full aspect-square flex items-end justify-center">
<!--                  <p class="text-2xl font-bold text-white mb-9 text-center z-10">Rabaty do -<span>30%</span></p>-->

                  <img alt="Produkt" [src]="slide.content" class="absolute h-[466px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                </div>
              </app-slide>
            }
          </app-slider>
        </div>
        <div class="relative w-fit mx-auto">
          <button (click)="toggleBenefits()" type="button" class="block cursor-pointer z-20 mx-auto -mt-4 mb-8 text-white text-lg">Sprawdź, listę korzyści &gt;</button>
        </div>
        <div class="flex items-center justify-center gap-x-2 justify-self-end">
          <a routerLink="register" class="btn btn-primary-reversed">
            zarejestruj się
          </a>

          <a routerLink="login" class="btn btn-primary !text-gray-dark">
            zaloguj się
          </a>
        </div>

        <!-- fancy background, może trzeba to zamienić na img per slide? Nie byłem w stanie wyeksportować tego z projektu -->
        <div class="intro-slider-background absolute top-0 left-0 w-full h-full -z-10 overflow-hidden">
          <div style="height: 520px" class="absolute aspect-square rounded-full opacity-15 bg-gray-dark bottom-0 right-0 translate-y-1/3 translate-x-1/2 blur-lg"></div>
          <div style="height: 320px" class="absolute aspect-square rounded-full opacity-50 bg-white top-1/2 left-0 -translate-y-1/2 -translate-x-2/3 blur-[70px]"></div>
          <div style="height: 120px" class="absolute aspect-square rounded-full opacity-50 bg-white top-36 right-0 translate-x-1/2 blur-2xl"></div>
          <div style="height: 520px" class="absolute aspect-square rounded-full opacity-40 bg-black top-0 left-1/2 -translate-y-1/2 -translate-x-1/2 blur-[100px]"></div>
        </div>
      </div>
    </div>
  </div>
  <div [class]="showBenefits ? 'fixed' : 'hidden'" class="z-10 left-1/2 -mr-[50%] -translate-x-1/2 md:translate-x-0 lg:translate-x-1/4 2xl:translate-x-2/3 top-1/2 -translate-y-1/2 bg-white md:px-10 md:py-5 px-4 py-2 rounded-2xl shadow-2xl">
    <h2 class="text-brand-darker text-2xl font-semibold text-center mt-6">DLACZEGO WARTO?</h2>
    <div class="text-white space-y-4 mx-2 mt-4 mb-10">
      <div class="rounded-2xl bg-brand-darker flex items-center pl-4 pr-7 py-2">
        <img class="h-9 mr-5" alt="Rabat powitalny" src="assets/images/icons/rabat_powitalny_white.svg">
        <p class="pt-0.5">Rabat powitalny</p>
      </div>
      <div class="rounded-2xl bg-brand-darker flex items-center pl-4 pr-7 py-2">
        <img class="h-9 mr-5" alt="Stały rabat" src="assets/images/icons/stały_rabat_white.svg">
        <p class="pt-0.5">Stały rabat</p>
      </div>
      <div class="rounded-2xl bg-brand-darker flex items-center pl-4 pr-7 py-2">
        <img class="h-9 mr-5" alt="Rabaty za punkty" src="assets/images/icons/ikona-punkty_white.svg">
        <p class="pt-0.5">Rabaty za punkty</p>
      </div>
      <div class="rounded-2xl bg-brand-darker flex items-center pl-4 pr-7 py-2">
        <img class="h-9 mr-5" alt="Rabat urodzinowy" src="assets/images/icons/rabat_urodzinowy_white.svg">
        <p class="pt-0.5">Rabat urodzinowy</p>
      </div>
      <div class="rounded-2xl bg-brand-darker flex items-center pl-4 pr-7 py-2">
        <img class="h-9 mr-5" alt="Rabaty dla Klubowiczek" src="assets/images/icons/rabaty_dla_klubowiczek_white.svg">
        <p class="pt-0.5">Rabaty dla Klubowiczek</p>
      </div>
      <div class="rounded-2xl bg-brand-darker flex items-center pl-4 pr-7 py-2">
        <img class="h-9 mr-5" alt="Wydłużony termin zwrotu" src="assets/images/icons/zwroty_white.svg">
        <p class="pt-0.5">Wydłużony termin zwrotu</p>
      </div>
    </div>
  </div>
</app-theming>

