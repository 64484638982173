import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForOf, NgIf} from '@angular/common';
import {BaseUserComponent} from '../base-user/base-user.component';
import JsBarcode from 'jsbarcode';
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {UserServices} from '../../_services/user.service';
import {CustomerServices} from '../../_services/customer.services';
import {CodesService} from '../../_services/codes.service';
import {InvoicesService} from '../../_services/invoices.service';
import moment from 'moment';
import {InvoicesResponse} from '../../_interfaces/invoices.response';
import {debounceTime} from 'rxjs';
import {CampaignsService} from '../../_services/campaigns.service';
import {CampaignResponse} from '../../_interfaces/campaign.response.interface';

interface KeyValue {
  [key: string]: boolean;
}

@Component({
  selector: 'app-welcome-user',
  standalone: true,
  imports: [
    NgIf,
    BaseUserComponent,
    FormsModule,
    ReactiveFormsModule,
    NgForOf
  ],
  templateUrl: './user.component.html',
  styleUrl: './user.component.css'
})
export class UserComponent implements AfterViewInit,OnInit {
  public clubId: any;
  public searchForm: any;
  public searchFormError: string = '';
  public codes: any;
  public invoices: InvoicesResponse[] = [];
  public checkedCoupons: KeyValue = {};
  public checkedCampaigns: KeyValue = {};
  public campaigns!: CampaignResponse[];

  constructor(
    private route: ActivatedRoute,
    private userService: UserServices,
    private router: Router,
    private formBuilder: FormBuilder,
    private customerService: CustomerServices,
    private codesService: CodesService,
    private invoiceServices: InvoicesService,
    private campaignService: CampaignsService
  ) {
    this.clubId = this.route.snapshot.queryParams['club'];
    this.searchForm = this.formBuilder.group({
      phone: new FormControl('', [Validators.required, Validators.minLength(3)]),
    })
    this.searchForm.valueChanges.pipe(
      debounceTime(500)
    ).subscribe({
      next: (value: any) => {
        if (this.validation()) {
          this.search();
        }
      }
    })
  }

  ngAfterViewInit(): void {
    if (this.clubId) {
      JsBarcode('#barcode', this.clubId);
      this.codesService.getCodes(this.userService.getUser(), this.clubId, false).subscribe(codes => {
        this.codes = codes;
      });
      this.invoiceServices.getInvoices(this.userService.getUser(), this.clubId).subscribe(invoices => {
        this.invoices = invoices;
      })
    }
  }

  ngOnInit(): void {
    this.userService.loginWithToken(this.userService.getToken() ?? '');
    let user = this.userService.getUser();

    if (user.email === '') {
      this.router.navigate(['/user/login']);
    }
    this.campaignService.getCampaigns(false, 'RABATY SEZONOWE').subscribe({
      next: data => {
        this.campaigns = data;
      }
    })
  }

  search() {
    if (this.searchForm.status === 'VALID') {
      let phone = this.searchForm.controls['phone'].value;
      this.customerService.getCustomers(phone).subscribe({
        next: (data: any) => {
          if (data[0]?.clubId) {
            this.router.navigate(['/user'], {queryParams: {club: data[0].clubId}}).then(() => {
              window.location.reload();
            });
          } else {
            this.router.navigate(['/user']);
            this.clubId = '';
            this.searchFormError = 'Nie znaleziono Klienta po numerze telefonu/adresie e-mail.';
          }
        }
      })
    }
  }

  private validation() {
    let phoneErrors = this.searchForm.controls['phone'].errors;

    if (phoneErrors?.required === true) {
      this.searchFormError = "Wartość jest wymagana"
    }
    if (phoneErrors?.minlength) {
      this.searchFormError = "Minimum 3 znaki"
    }
    if (phoneErrors === null) {
      this.searchFormError = "";
    }

    return this.searchFormError === "";
  }

  protected readonly moment = moment;
  protected readonly Array = Array;

  toggleCoupon(id: string) {
    this.checkedCoupons[id] = !(this.checkedCoupons[id] && this.checkedCoupons[id] === true);
    this.newToken();
  }
  toggleCampaign(id: string) {
    this.checkedCampaigns[id] = !(this.checkedCampaigns[id] && this.checkedCampaigns[id] === true);
    this.newToken();
  }
  private newToken() {
    let couponArr : Array<string> = [];
    let campaignArr : Array<string> = [];
    let data = {
      coupons: couponArr,
      campaigns: campaignArr,
      clubId: this.clubId,
    };
    for (const [key, value] of Object.entries(this.checkedCoupons)) {
      if (value) {
        data.coupons.push(key);
      }
    }
    for (const [key, value] of Object.entries(this.checkedCampaigns)) {
      if (value) {
        data.campaigns.push(key);
      }
    }
    this.customerService.postSaleAction(this.userService.getUser(), data).subscribe({
      next: (data: any) => {
        JsBarcode('#barcode', data.token);
      }
    });
  }
}
