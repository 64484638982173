import { Component } from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {ContentCardComponent} from '../../common/content-card/content-card.component';
import {PageTitleComponent} from '../page-title/page-title.component';
import {RouterLink} from '@angular/router';
import {InvoicesService} from '../../_services/invoices.service';
import {CustomerServices} from '../../_services/customer.services';
import {InvoicesResponse} from '../../_interfaces/invoices.response';
import moment from 'moment';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgForOf, NgIf} from '@angular/common';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    BaseComponent,
    ContentCardComponent,
    PageTitleComponent,
    RouterLink,
    NgxPaginationModule,
    NgForOf,
    NgIf
  ],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.css'
})
export class OrdersComponent {

  public invoices: InvoicesResponse[] = [];
  public showPositions: any = {};
  public page = 1;
  public totalItems: number = 0;
  public showOrders = true;

  constructor(
    protected invoiceServices: InvoicesService,
    protected customerServices: CustomerServices,
    protected spinnerService: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.updateItems();
  }

  togglePosition(param: string) {
    this.showPositions[param] = !this.showPositions[param];
  }

  onPageChange($event: number) {
    this.page = $event
    this.updateItems();
  }

  private updateItems() {
    this.invoiceServices.getInvoices(this.customerServices.getCustomer(), null, this.page).subscribe(invoices => {
      this.invoices = invoices["hydra:member"];
      this.totalItems = invoices["hydra:totalItems"];

      if( !this.invoices.length ) {
        this.showOrders = false;
      }
      this.spinnerService.hide('main');
    });
  }

  protected readonly Array = Array;
  protected readonly moment = moment;
}
