<app--unauthorized-base>
  <h1 class="text-2xl font-bold mb-0.5">Dokończ rejestrację</h1>
  <form novalidate (submit)="submit($event)" [formGroup]="this.finalizeForm" class="grow flex flex-col">
    <div class="mt-3 flex flex-col gap-y-6 mb-4">
      <app-google-places-autocomplete [control]="finalizeForm.controls['street']" name="street" label="Ulica" [controlError]="this.streetError" (choosePlaceEvent)="setAddressFromGooglePlace($event)" />
      <app-form-control-text-field [control]="finalizeForm.controls['postCode']" name="postCode" label="Kod pocztowy" [controlError]="this.postCodeError" />
      <app-form-control-text-field [control]="finalizeForm.controls['city']" name="city" label="Miasto" controlError="Miasto jest wymagane" />
      <app-form-control-date-field [control]="finalizeForm.controls['birthDate']" name="birthDate" label="Data urodzenia" [controlError]="this.birthDateError" />
      <app-form-control-text-field [control]="finalizeForm.controls['email']" *ngIf="this.showEmail" name="email"  label="Adres e-mail" controlError="Adres email jest wymagany" />
      <app-form-control-text-field [control]="finalizeForm.controls['phoneNumber']" *ngIf="this.showPhoneNumber" name="phone-number"  defaultValue="+48" label="Numer telefonu" [controlError]="this.phoneControlError" />
    </div>
    <button #submitButton type="submit" class="btn btn-secondary btn-text-shadow mt-auto">dalej</button>
    <p *ngIf="this.trialEnded === '0'" class="text-center mt-6">
      <a routerLink="/register/finished" class="text-brand-medium font-bold btn-text-shadow underline">pomiń</a>
    </p>
  </form>
</app--unauthorized-base>
