import {Component, ViewChild, signal, effect, Input} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {GoogleMapsModule, MapInfoWindow} from '@angular/google-maps';
import {MapMarkerInterface}  from '../../_interfaces/map.marker.interface';
import {ShopsResponse} from '../../_interfaces/shops.response.interface';

@Component({
  selector: 'app-map',
  standalone: true,
  templateUrl: './map-component.component.html',
  imports: [
    CommonModule,
    GoogleMapsModule,
    RouterModule,
  ],
  styleUrls: ['./map-component.component.css']
})
export class MapComponentComponent {
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  center: google.maps.LatLngLiteral = { lat: 53.11777728011778, lng: 18.009626955768983 };
  zoom = 6;
  _markers: MapMarkerInterface[] = [];
  nearestMarkerIndex: number|null = null
  @Input()
  set markers(markers: MapMarkerInterface[]) {
    this._markers = markers;
    this.selectNearest();
  }
  get markers() {return this._markers}
  anchors: any = [];
  selectedShop = signal<ShopsResponse | null>(null);

  constructor() {
  }

  addAnchor(marker: google.maps.Marker) {
    this.anchors.push(marker);
  }

  selectNearest() {
    if( navigator.geolocation ) {
      navigator.geolocation.getCurrentPosition(pos => {
        if( this.markers.length ) {
          let minDistance = 999999;
          this.markers.forEach((marker, index) => {
            const distance = Math.sqrt((pos.coords.latitude - marker.position.lat) * (pos.coords.latitude - marker.position.lat) + (pos.coords.longitude - marker.position.lng) * (pos.coords.longitude - marker.position.lng));
            if( distance < minDistance ) {
              minDistance = distance;
              this.nearestMarkerIndex = index;
            }
          });
        }
      });
    }
  }

  openNearest() {
    setTimeout(() => {
      if( this.nearestMarkerIndex !== null ) {
        const anchor = this.anchors[this.nearestMarkerIndex];
        anchor.getAnchor = () => {
          return anchor;
        }
        this.selectedShop.set(this.markers[this.nearestMarkerIndex].shop);
        this.openInfoWindow(this.markers[0].shop, anchor);
      }
    }, 1000);
  }

  openInfoWindow(shop: ShopsResponse, marker: any): void {
    this.selectedShop.set(shop);
    if (this.infoWindow) {
      this.infoWindow.open(marker);
    }
  }
}
