import {Component, ElementRef, EventEmitter, Input, numberAttribute, Output, viewChild} from '@angular/core';
import {NgIf} from '@angular/common';

export enum CouponValueMode {
  Percent = 0,
  Price
}

@Component({
  selector: 'app-coupon',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './coupon.component.html',
  styleUrl: './coupon.component.css'
})
export class CouponComponent {
  protected description = viewChild<ElementRef>('description');
  protected descriptionEl!: HTMLElement;
  @Input({ required: true }) value = 0;
  @Input({ required: false }) mode = CouponValueMode.Percent;
  @Input() descriptionText: string|null = null;
  @Input() canActivate = true;
  @Input() fullWidthDescription = false;
  @Output() couponClicked: EventEmitter<void> = new EventEmitter;

  protected readonly Array = Array;

  ngOnInit() {
    this.descriptionEl = this.description()!.nativeElement;
  }

  handleClick() {
    if (this.canActivate) {
        this.couponClicked.emit();
    }
    if ( this.descriptionText !== null ) {
      this.toggleDescription();
    }
  }

  protected toggleDescription() {
    this.descriptionEl.classList.toggle('opacity-0');
  }
}
