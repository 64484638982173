<app--authorized-base>
  <app-content-card>
    @if( showOrders && invoices.length ) {
      <app-page-title label="Moje zakupy" routerLinkUrl="/welcome"/>
      <div class="text-gray-dark">
        <div class="divide-y">
          <div *ngFor="let invoice of this.invoices | paginate: { itemsPerPage: 10, currentPage: this.page, totalItems: this.totalItems }" class="pb-6 pt-5">
            <p class="text-lg font-semibold">Zakupione produkty</p>
            <div class="flex justify-between">
              <p>{{moment(invoice.sellDate).format('DD.MM.YYYY')}}</p>
              <p class="text-right ml-12">Nr paragonu {{invoice.number}}</p>
            </div>
            <br>
            <div class="flex justify-between">
              <div class="mr-12">
                <span>Sklep {{invoice.shop.name}}</span><br>
                <span>{{invoice.shop.street}}</span><br>
                <span>{{invoice.shop.zip}} {{invoice.shop.city}}</span>
              </div>
              <div class="text-end">
                <span>Łączna kwota {{invoice.quote}} zł</span><br>
                <span>w tym VAT</span>
              </div>
            </div>
            <div class="flex justify-end">
              <a routerLink="/orders/order" [queryParams]="{invoiceId: invoice.id}" class="text-white rounded-3xl font-semibold bg-brand-medium py-2 px-9 text-sm">Szczegóły</a>
            </div>
          </div>
        </div>
        <div *ngIf="totalItems > 10" class="flex justify-center">
          <p>Strona: <pagination-controls (pageChange)="onPageChange($event)" previousLabel="Poprzednia" nextLabel="Następna"></pagination-controls></p>
        </div>
      </div>
    } @else if (!showOrders) {
      <div class="text-gray-dark text-2xl font-bold my-44 text-center">
        Klubowiczko, brak zakupów<br>
        z Klubem QUIOSQUE
      </div>
      <a routerLink="/welcome" class="btn btn-secondary mx-auto px-20 btn-text-shadow">wróć do strony głównej</a>
    }
  </app-content-card>
</app--authorized-base>
