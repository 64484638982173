<app--authorized-base pageWrapperClasses="welcome-page">
  <div #qrHeader class="w-full flex flex-col md:flex-row justify-center md:justify-between md:items-center">
    <div class="md:order-2">
      <div *ngIf="this.customer" class="flex justify-center md:justify-between">
        <h1 class="text-2xl font-semibold text-white mr-4">{{this.customer.points.toString()}} pkt</h1>
        <svg width="50" class="-m-2 -mt-3" id="Graphic_elemetns" xmlns="http://www.w3.org/2000/svg" version="1.1" fill="#fff" viewBox="0 0 1200 1200">
          <g id="rabat_stały">
            <path id="Path_338" d="M599.8,230.7c-204,0-369.3,165.4-369.3,369.3s165.4,369.3,369.3,369.3,369.3-165.4,369.3-369.3c.5-203.5-164.1-368.9-367.6-369.3-.6,0-1.2,0-1.7,0ZM599.8,950.1c-193.4,0-350.1-156.8-350.1-350.1s156.8-350.1,350.1-350.1,350.1,156.8,350.1,350.1c0,193.3-156.6,350.1-350,350.1,0,0-.1,0-.2,0Z"/>
            <path id="Path_339" d="M625,411.2l51.1,102.3,113.5,16c16.2,2.2,27.5,17.2,25.3,33.3-.9,6.8-4.2,13.1-9.3,17.7l-81.5,80,19.2,111.9c2.9,16.3-7.9,31.9-24.2,34.8-6.5,1.2-13.1.2-19-2.8l-100.7-52.8-100.7,52.8c-14.4,7.8-32.3,2.5-40.2-11.9-3.3-6.1-4.4-13.3-3-20.1l19.2-111.9-81.5-80c-11.9-11.6-12.1-30.6-.5-42.5,4.4-4.5,10.2-7.6,16.5-8.6l113.5-16,51.1-102.3c11.3-22.3,36.9-28.7,51.3,0Z"/>
          </g>
        </svg>
      </div>
      <a routerLink="/point-exchange" class="mb-7 block text-center">
        <p class="text-white font-light text-lg">Wymień punkty &gt;</p>
      </a>
    </div>
    <div class="flex flex-col md:flex-row">
      <div class="flex justify-center">
        <div class="absolute top-0 left-0 w-full h-[510px] -z-10">
        </div>
        <div class="md:pr-10 pb-10">
          <svg id="barcode"></svg>
        </div>
      </div>
      <div class="pt-5 hidden md:block">
        <h1 class="text-2xl font-bold text-white mb-3">Cześć {{ this.customer.name }}!</h1>
        <h2 class="text-white font-light mb-7 text-xl">Pokaż swój kod sprzedawcy<br /> aby skorzystać z promocji</h2>
      </div>
    </div>
  </div>
  <app-content-card additionalClasses="pb-8">
    <div *ngIf="this.codes && this.codes.length > 0">
      <h2 class="font-bold text-2xl text-qsq-medium w-full pt-6 md:pt-16 pb-5 md:pb-10 pr-2 md:pr-20 pl-2 md:pl-20">Aktywne kupony</h2>
      <div>
        <app-slider [slidePxGap]="16" [slidePxWidth]="320" [slidesPerPage]="2" [stretchGap]="false">
          @for ( i of this.codes; track $index) {
            <app-slide>
              <app-coupon [mode]="i.campaignCompany.pointCost ? CouponValueMode.Price: CouponValueMode.Percent" [value]="i.campaignCompany.campaign.percent" [descriptionText]="i.campaignCompany.campaign.longText">
                {{i.campaignCompany.campaign.shortText}}
              </app-coupon>
            </app-slide>
          }
        </app-slider>
      </div>
    </div>
    <h2 class="font-bold text-2xl text-qsq-medium w-full pt-6 md:pt-16 pb-5 md:pb-10 pr-2 md:pr-20 pl-2 md:pl-20">Aktualne promocje</h2>
    <div *ngIf="this.campaigns">
      <app-slider [slidePxWidth]="320" [slidesPerPage]="2" [slidePxGap]="16" [stretchGap]="true">
        <app-slide *ngFor="let group of this.campaigns">
          <div class="bg-gray-lighter rounded-3xl flex flex-col items-center py-8 px-10 shrink-0">
            <img src="{{group.groupImage}}" class="h-60 object-cover" alt="Rabaty sezonowe" />
            <p class="text-brand-medium font-bold mt-4 mb-6">{{group.groupName}}</p>
            <a routerLink="/brands/brand-page" [queryParams]="{group: group.groupName}" class="btn btn-primary w-full !bg-qsq-medium !text-white">Sprawdź</a>
          </div>
        </app-slide>
      </app-slider>
    </div>
  </app-content-card>
</app--authorized-base>
