import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {HeaderUtils} from '../_utils/HeaderUtils';
import {UserTokenInterface} from '../_interfaces/user-token.interface';

@Injectable({
  providedIn: 'root'
})
export class CodesService {

  constructor(private httpClient: HttpClient) {
  }

  postCodes(data: any) {
    return this.httpClient.post(environment.apiUrl + '/codes', data, {
      headers: HeaderUtils.createHeaders('POST', localStorage.getItem('token') ?? ''),
    });
  }

  getCodes(userOrCustomer: UserTokenInterface, clubId: any, notUsed: boolean|null = null) {
    let queryParams = '';
    if (notUsed !== null) {
      queryParams = '&used=' + notUsed;
    }

    return this.httpClient.get(userOrCustomer.getApiUrl() + '/codes?customer.clubId=' + clubId + queryParams, {
      headers: HeaderUtils.createHeaders('GET', userOrCustomer.getToken() ?? ''),
    });
  }

  getCode(id: any) {
    return this.httpClient.get(environment.apiUrl + '/codes/' + id, {
      headers: HeaderUtils.createHeaders('GET', localStorage.getItem('user_token') ?? ''),
    });
  }
}
