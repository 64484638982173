import {Component, ElementRef, OnInit, viewChild} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {AuthServices} from '../../_services/auth.services';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {FormControlTextFieldComponent} from '../../common/form-control-text-field/form-control-text-field.component';
import {RegisterResponse} from '../../_interfaces/register.response.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForOf, NgIf} from '@angular/common';
import {FormAlertComponent} from '../../common/form-alert/form-alert.component';
import {CheckboxComponent} from "../../common/checkbox/checkbox.component";
import {ConsentsServices} from '../../_services/consents.services';
import {NgxSpinnerComponent, NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    BaseComponent,
    FormControlTextFieldComponent,
    ReactiveFormsModule,
    NgIf,
    FormAlertComponent,
    CheckboxComponent,
    NgForOf,
    NgxSpinnerComponent
  ],
  templateUrl: './register-email.component.html',
  styleUrl: './register-email.component.css',
  providers: [AuthServices]
})
export class RegisterEmailComponent implements OnInit {
  protected submitButton = viewChild<ElementRef>('submitButton');
  public registerForm: any;
  public phoneControlError: string = '';
  public registerFormError: string = '';
  public consents: any = [];
  public checked: any = {};
  public inviteCode: string = '';
  public submitted = false;

  constructor(
    public authServices: AuthServices,
    formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private consentsService: ConsentsServices,
    private spinnerService: NgxSpinnerService
  ) {
    this.spinnerService.show('main');
    this.registerForm = formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    })
    this.registerForm.valueChanges.subscribe({
      next: (value: string) => {
      }
    });
  }

  ngOnInit(): void {
    sessionStorage.removeItem('registration')
    sessionStorage.removeItem('token')
    this.inviteCode = this.route.snapshot.queryParams['invite'] ?? '';

    this.consentsService.getConsents().subscribe({
      next: data => {
        this.consents = data;
        this.spinnerService.hide('main');
      }
    });
  }

  consentChange(value: boolean, id: string) {
    this.checked[id] = value;
  }

  submit(event: SubmitEvent) {
    event.preventDefault();
    this.submitted = true;
    for (const i in this.registerForm.controls) {
      this.registerForm.controls[i].markAsTouched();
      this.registerForm.controls[i].markAsDirty();
    }
    if (this.registerForm.status === 'VALID') {
      this.submitButton()!.nativeElement.classList.toggle('opacity-30');

      let data = this.registerForm.value;
      data.inviteCode = this.inviteCode;

      for (const consent of this.consents) {
        if( this.checked[consent.id] !== true ) {
          return;
        }
      }

      data.consents = [];
      Object.entries(this.checked).forEach((entry) => {
        if( entry[1] ) {
          data.consents.push(entry[0]);
        }
      });

      this.authServices.registerByEmail(data).subscribe({
        next: (value: RegisterResponse) => {
          sessionStorage.setItem('registration', JSON.stringify({email: this.registerForm.controls['email'].value}));
          this.router.navigate(['/register/auth-code']);
          this.submitButton()!.nativeElement.classList.toggle('opacity-30');},
        error: (error: any) => {
          this.registerFormError = error;
          this.submitButton()!.nativeElement.classList.toggle('opacity-30');
        }
      });
    }
  }
}
