import { Component } from '@angular/core';
import {AuthServices} from '../../_services/auth.services';
import {Router} from '@angular/router';

@Component({
  selector: 'app-logout',
  standalone: true,
  imports: [],
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.css'
})
export class LogoutComponent {
  constructor(authService: AuthServices, router: Router) {
    authService.removeToken();
    router.navigate(['/']);
  }
}
