import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {PageTitleComponent} from '../page-title/page-title.component';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {ConsentsServices} from '../../_services/consents.services';
import {NgForOf, NgIf} from '@angular/common';
import {CustomerServices} from '../../_services/customer.services';
import {RouterLink} from '@angular/router';
import {ContentCardComponent} from '../../common/content-card/content-card.component';
import {NgxSpinnerService} from 'ngx-spinner';

interface KeyValue {
  [key: string]: boolean;
}

@Component({
  selector: 'app-consents',
  standalone: true,
  imports: [
    BaseComponent,
    PageTitleComponent,
    FormsModule,
    ReactiveFormsModule,
    NgForOf,
    NgIf,
    RouterLink,
    ContentCardComponent
  ],
  templateUrl: './consents.component.html',
  styleUrl: './consents.component.css'
})
export class ConsentsComponent implements OnInit {
  public consents: any;
  public checked: KeyValue = {};
  public formSubmitted = false;

  constructor(
    private consentsService: ConsentsServices,
    private customerService: CustomerServices,
    private spinnerService: NgxSpinnerService) {
  }

  ngOnInit() {
    this.consentsService.getConsents().subscribe({
      next: data => {
        this.consents = data;
        this.customerService.getMe().subscribe(
          data => {
            for (const consent of data.consents) {
              this.checked[consent.id] = true;
            }
            this.spinnerService.hide('main');
          });
      }
    });
  }

  onSubmit($event: SubmitEvent) {
    $event.preventDefault();

    let data:{consents: string[]} = {consents: []}
    for (const property in this.checked) {
      if (this.checked[property]) {
        data.consents.push("/api/consents/" + property);
      }
    }

    this.customerService.patchCustomer(data, this.customerService.getCustomer().id).subscribe({
      next: data => {
        this.formSubmitted = true;
      }
    });
  }

  setupChecked(id: any) {
    this.checked[id] = !(this.checked[id] && this.checked[id] === true);
  }
}
