<app--authorized-base>
  <app-content-card additionalClasses="pb-8 md:pb-14">
    <app-page-title label="Lista korzyści" />
    <div class="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-7 text-gray-dark">
        <div class="flex items-center bg-white shadow-[0px_0px_25px_#0000001A] pl-4 pr-4 py-7 rounded-2xl">
          <img class="w-20 mr-4" alt="Rabat powitalny" src="/assets/images/icons/rabat_powitalny.svg">
          <div class="h-full pt-3">
            <p class="font-semibold text-lg">Rabat powitalny</p>
            <p>15% na następne zakupy</p>
          </div>
        </div>
        <div class="flex items-center bg-white shadow-[0px_0px_25px_#0000001A] pl-4 pr-4 py-7 rounded-2xl row-span-2">
          <img class="w-20 mr-4" alt="Rabaty za punkty" src="/assets/images/icons/ikona-punkty.svg">
          <div class="h-full pt-3">
            <p class="font-semibold text-lg">Rabaty za punkty</p>
            <p class="font-semibold text-lg">1000 punktów:</p>
            <ul class="list-disc list-inside">
              <li>50 zł rabatu</li>
            </ul>
            <p class="font-semibold text-lg">1500 punktów:</p>
            <ul class="list-disc list-inside">
              <li>100 zł rabatu</li>
            </ul>
            <p class="font-semibold text-lg">2000 punktów:</p>
            <ul class="list-disc list-inside">
              <li>200 zł rabatu</li>
            </ul>
          </div>
        </div>
        <div class="flex items-center bg-white shadow-[0px_0px_25px_#0000001A] pl-4 pr-4 py-7 rounded-2xl">
          <img class="w-20 mr-4" alt="Stały rabat" src="/assets/images/icons/stały_rabat.svg">
          <div class="h-full pt-3">
            <p class="font-semibold text-lg">Stały rabat</p>
            <p>10% na nową kolekcje</p>
          </div>
        </div>
        <div class="flex items-center bg-white shadow-[0px_0px_25px_#0000001A] pl-4 pr-4 py-7 rounded-2xl">
          <img class="w-20 mr-4" alt="Rabat urodzinowy" src="/assets/images/icons/rabat_urodzinowy.svg">
          <div class="h-full pt-3">
            <p class="font-semibold text-lg">Rabat urodzinowy</p>
            <p>30% na cały koszyk</p>
          </div>
        </div>
        <div class="flex items-center bg-white shadow-[0px_0px_25px_#0000001A] pl-4 pr-4 py-7 rounded-2xl">
          <img class="w-20 mr-4" alt="Wydłużony termin zwrotu" src="/assets/images/icons/zwroty.svg">
          <div class="h-full pt-3">
            <p class="font-semibold text-lg">Wydłużony termin zwrotu</p>
            <p>do 30 dni od daty zakupu</p>
          </div>
        </div>
        <div class="flex items-center bg-white shadow-[0px_0px_25px_#0000001A] pl-4 pr-4 py-7 rounded-2xl">
          <img class="w-20 mr-4" alt="Rabaty dla Klubowiczek" src="/assets/images/icons/rabaty_dla_klubowiczek.svg">
          <div class="h-full pt-3">
            <p class="font-semibold text-lg">Tylko dla Klubowiczek</p>
            <p>Rabaty i promocje</p>
          </div>
        </div>
        <div class="pl-10 flex items-end">
          <p>
            * warunki skorzystania z rabatu zawarte są w <a class="underline" target="_blank" routerLink="/terms-of-service">regulaminie</a>.
          </p>
        </div>
    </div>
  </app-content-card>
</app--authorized-base>
