import {UserTokenInterface} from '../_interfaces/user-token.interface';
import {environment} from '../../environments/environment';

export class Customer implements UserTokenInterface {
  getApiUrl(): string {
    return environment.apiUrl;
  }
  public email: string = '';
  public name: string = '';
  public id: string = '';
  public clubId: number = 0;
  public points: number = 0;

  storeToken(token:string){
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token') ?? null;
  }
}
