import { Component } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {PageTitleComponent} from '../../page-title/page-title.component';
import {ContentCardComponent} from '../../../common/content-card/content-card.component';

@Component({
  selector: 'app-details',
  standalone: true,
  imports: [
    BaseComponent,
    PageTitleComponent,
    ContentCardComponent
  ],
  templateUrl: './details.component.html',
  styleUrl: './details.component.css'
})
export class DetailsComponent {

}
