<app--authorized-base>
  <app-content-card>
    <app-page-title label="Rabaty za punkty"/>
    <h2 class="text-brand-medium text-2xl font-semibold mt-8 mb-4">Zebrałaś: {{this.points.toString()}} pkt</h2>
    <p class="font-bold text-gray-dark mb-3">Sprawdź na co możesz je wymienić:</p>
    <app-slider *ngIf="campaigns.length > 0" [slidePxGap]="24" [slidePxWidth]="365" [slidesPerPage]="2" [stretchGap]="true" class="-mx-10">
      @for(i of this.campaigns; track $index) {
        <app-slide>
          <app-coupon [canActivate]="this.points >= i.pointCost" [mode]="CouponValueMode.Price" [value]="i.campaign.percent" (couponClicked)="activateCoupon(i.id)" [descriptionText]="this.findDescription(i.id) ? 'Twój kod został aktywowany' : 'Nie masz wystarczającej ilości punktów'" [fullWidthDescription]="true">
            <div>{{i.campaign.shortText}}</div>
            <div [class]="this.points >= i.pointCost ? 'bg-brand-medium' : 'bg-gray-light'" class="text-white rounded-full font-bold w-fit px-4 py-1 mt-2 -ml-2">
              Aktywuj za {{i.pointCost}} pkt
            </div>
          </app-coupon>
        </app-slide>
      }
    </app-slider>
  </app-content-card>
</app--authorized-base>
