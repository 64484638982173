<div (click)="handleClick()" class="group cursor-pointer relative bg-gray-lighter rounded-2xl flex items-stretch overflow-hidden">
  <div #discount
    class="flex shrink-0 items-center justify-center bg-brand-medium text-white text-2xl font-bold w-28 rounded-l-2xl">
    <div *ngIf="mode == 0">{{ value }}%</div>
    <div *ngIf="mode == 1">{{ value }}zł</div>
  </div>
  <div class="absolute left-28 -translate-x-1/2 top-0 h-full flex flex-col justify-center gap-y-2.5 z-10">
    @for (i of Array(8); track $index) {
      <span class="size-3 aspect-square bg-[#F8F8F8] rounded-full"></span>
    }
  </div>
  <div class="relative flex flex-col justify-center grow text-gray-dark gap-y-2 h-44 pl-7 pr-2">
    <ng-content/>
  </div>
  <div #description [class]="(fullWidthDescription ? 'z-20 font-semibold text-lg text-center ' : 'ml-28 pr-28 pl-7 ') + (canActivate ? 'bg-brand-medium ' : 'bg-gray-light ')"
       class="opacity-0 transition-all absolute top-0 left-0 w-full h-full flex flex-col justify-center text-white gap-y-2 pr-2 rounded-r-2xl">
    <p>{{ descriptionText }}</p>
  </div>
</div>
