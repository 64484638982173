<app--authorized-base pageWrapperClasses="welcome-page" class="brand-qsq">
  <app-content-card>
  <app-page-title label="Paragony" routerLinkUrl="/welcome" />
  <div class="text-gray-dark mx-2">
    <div role="list" class="w-full divide-y divide-solid">
      <div *ngFor="let invoice of this.invoices | paginate: { itemsPerPage: 10, currentPage: this.page, totalItems: this.totalItems }" class="gap-4 py-10">
        <div class="flex justify-between min-w-0 gap-x-4 mb-3">
          <p class="float-left text-lg font-semibold">Paragon nr {{invoice.number}}</p>
          <p class="float-right">{{ moment(invoice.sellDate).format('DD.MM.YYYY') }}</p>
        </div>
        <div class="flex flex-col gap-y-2 ">
          <div *ngIf="invoice.shop.id" class="mb-3">
            Sklep {{invoice.shop.name}} <br />
            {{invoice.shop.street}}, {{invoice.shop.zip}} {{invoice.shop.city}}
          </div>
          <ul class="ml-4 list-outside list-decimal">
            <li *ngFor="let element of invoice.invoiceElements">
              <ul class="mb-6">
                <li><span class="float-left">{{ element.name }}</span> <span class="float-right">Ilość: {{element.quantity}}</span></li><br />
                <li><span class="float-left">SKU: {{element.sku}}</span> <span class="float-right">{{element.quote}} zł</span></li><br />
              </ul>
            </li>
          </ul>
        </div>
        <div class="text-end">
          <p class="font-semibold text-lg">Łączna kwota {{invoice.quote}} zł</p>
          <p>w tym VAT</p>
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <p>Strona: <pagination-controls (pageChange)="onPageChange($event)" previousLabel="Poprzednia" nextLabel="Następna"></pagination-controls></p>
    </div>
  </div>
  </app-content-card>
</app--authorized-base>
