import {Component, Input} from '@angular/core';
import {RouterLink} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-page-title',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './page-title.component.html',
  styleUrl: './page-title.component.css',
  host: {'class': 'w-[48rem] max-w-full'}
})
export class PageTitleComponent {
  @Input({required: true}) label = '';
  /**
   * @deprecated will be ignored
   */
  @Input() routerLinkUrl = '';

  constructor(private location: Location) {
  }

  goBack() {
    this.location.back();
  }
}
