import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {ContentCardComponent} from '../../common/content-card/content-card.component';
import {PageTitleComponent} from '../page-title/page-title.component';
import {SliderComponent} from '../../common/slider/slider.component';
import {CouponComponent, CouponValueMode} from '../../common/coupon/coupon.component';
import {SlideComponent} from '../../common/slider/slide/slide.component';
import {CustomerServices} from '../../_services/customer.services';
import {Customer} from '../../classes/customer';
import {CampaignsService} from '../../_services/campaigns.service';
import {CampaignResponse} from '../../_interfaces/campaign.response.interface';
import {KeyValue, NgIf} from '@angular/common';
import {CodesService} from '../../_services/codes.service';
import {Subject} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-point-exchange',
  standalone: true,
  imports: [
    BaseComponent,
    ContentCardComponent,
    PageTitleComponent,
    SliderComponent,
    CouponComponent,
    SlideComponent,
    NgIf
  ],
  templateUrl: './point-exchange.component.html',
  styleUrl: './point-exchange.component.css'
})
export class PointExchangeComponent implements OnInit {
  public customer: Customer;
  public campaigns: CampaignResponse[] = [];
  public descriptions: KeyValue<string,boolean>[] = [];
  public points = 0;

  constructor(
    private customerService: CustomerServices,
    private campaignService:CampaignsService,
    private codesService: CodesService,
    private spinnerService: NgxSpinnerService,
  ) {
    this.customer = customerService.getCustomer();
    this.points = this.customer.points;
  }

  ngOnInit(): void {
    this.campaignService.getCampaigns(false, '', true).subscribe({
      next: (campaigns: CampaignResponse[]) => {
        this.campaigns = campaigns;
        this.spinnerService.hide('main');
      }
    });
  }

  protected readonly Array = Array;

  activateCoupon(couponId: string) {
    this.spinnerService.show('main');
    this.codesService.postCodes({
      campaignCompany: "/api/campaign_companies/" + couponId,
      usePoints: true
    }).subscribe({
      next: data => {
        this.descriptions.push({
          key: couponId,
          value: true
        });
        this.spinnerService.hide('main');
        this.customerService.refreshToken().subscribe({
          next: (ev: any) => {
            this.customer.storeToken(ev.token);
            this.customerService.updateToken(ev.token);
            this.points = this.customerService.getCustomer().points;
          }
        });
      },
      error: error => {
        this.spinnerService.hide('main');
        this.descriptions.push({
          key: couponId,
          value: false
        });
      }
    });
  }

  findDescription(couponId: string) {
      return this.descriptions.find((element, index, array) => {
        if (element.key === couponId)
        {
          return element.value;
        }
        return false;
      });
  }

  protected readonly CouponValueMode = CouponValueMode;
}
