<app--unauthorized-base>
  <svg class="theme-icon mb-9 mx-auto" width="73" height="73" viewBox="0 0 73 73" xmlns="http://www.w3.org/2000/svg">
    <path d="M36.5 73C56.6584 73 73 56.6584 73 36.5C73 16.3416 56.6584 0 36.5 0C16.3416 0 0 16.3416 0 36.5C0 56.6584 16.3416 73 36.5 73ZM52.6113 29.7988L34.3613 48.0488C33.0211 49.3891 30.8539 49.3891 29.5279 48.0488L20.4029 38.9238C19.0627 37.5836 19.0627 35.4164 20.4029 34.0904C21.7432 32.7645 23.9104 32.7502 25.2363 34.0904L31.9375 40.7916L47.7637 24.9512C49.1039 23.6109 51.2711 23.6109 52.5971 24.9512C53.923 26.2914 53.9373 28.4586 52.5971 29.7846L52.6113 29.7988Z" />
  </svg>

  <h1 class="text-2xl font-bold mb-0.5">Gratulacje!</h1>
  <h2 class="text-gray-light">Twoje konto zostało założone. Możesz teraz przejść do panelu użytkownika</h2>

  <a routerLink="/welcome" class="btn btn-secondary btn-text-shadow mt-auto text-center px-8">przejdź do panelu użytkownika</a>
</app--unauthorized-base>
