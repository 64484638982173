import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {HeaderUtils} from '../_utils/HeaderUtils';
import {InvoicesResponse} from '../_interfaces/invoices.response';
import {HttpQueryBuilder} from '../_utils/HttpQueryBuilder';
import {UserTokenInterface} from '../_interfaces/user-token.interface';
import {HydraInvoicesResponse} from '../_interfaces/hydra.invoices.response';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(private httpClient: HttpClient) {
  }

  getInvoices(userOrCustomer: UserTokenInterface, clubId: any = null, page: number = 1) {
    let query = new HttpQueryBuilder();
    if (clubId !== null) {
      query.add('customer.clubId', clubId);
    }
    query.add('page', page.toString())

    return this.httpClient.get<any>(userOrCustomer.getApiUrl() + '/invoices' + query.build(), {
      headers: HeaderUtils.createHeaders('GET', userOrCustomer.getToken() ?? '', true),
    });
  }

  getInvoice(invoiceId: string) {
    return this.httpClient.get<InvoicesResponse>(environment.apiUrl + '/invoices/' + invoiceId , {
      headers: HeaderUtils.createHeaders('GET', localStorage.getItem('token') ?? ''),
    });
  }
}
