import {Component, ElementRef, viewChild} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {ContentCardComponent} from '../../common/content-card/content-card.component';
import {PageTitleComponent} from "../page-title/page-title.component";
import {FormControlTextFieldComponent} from '../../common/form-control-text-field/form-control-text-field.component';
import {FormBuilder, Validators} from '@angular/forms';
import {ValidatorsCustom} from '../../_utils/validators-custom.service';
import {Router} from '@angular/router';
import {CustomerServices} from '../../_services/customer.services';
import {NgIf} from '@angular/common';
import {PinPadComponent} from '../../common/pin-pad/pin-pad.component';
import {AuthServices} from '../../_services/auth.services';
import {HttpErrorResponse} from '@angular/common/http';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-delete-account',
  standalone: true,
  imports: [
    BaseComponent,
    ContentCardComponent,
    PageTitleComponent,
    NgIf,
    PinPadComponent
  ],
  templateUrl: './delete-account.component.html',
  styleUrl: './delete-account.component.css'
})
export class DeleteAccountComponent {

  public customerId: string = '';
  public tokenSending = false;
  protected submitButton = viewChild<ElementRef>('submitButton');
  public fullValidation: boolean = false;
  public pinPadValue: string = '';
  public pinPadError: string = '';
  public error: string = '';
  private isSubmitted: boolean = false;

  constructor(
    formBuilder: FormBuilder,
    private router: Router,
    private customerService: CustomerServices,
    private spinnerService: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    setTimeout(()=>{this.spinnerService.hide('main')});
    this.customerId = this.customerService.getCustomer().id;
  }

  submit(event: SubmitEvent) {
    event.preventDefault();
    this.fullValidation = true;
    if (this.pinPadError === '' && this.pinPadValue.length === 6) {
      this.submitButton()!.nativeElement.classList.toggle('opacity-30');
      this.isSubmitted = true;
      this.customerService.postDelete(this.pinPadValue).subscribe({
        next: result => {
          localStorage.removeItem('token');
          this.router.navigate(['/delete-account-success']);
        },
        error: (result: HttpErrorResponse) => {
          this.pinPadError = "Niepoprawny kod potwierdzający"
        }
      });
    }
  }

  sendpin() {
      this.customerService.getDeleteToken().subscribe((response) => {
        this.tokenSending = true;
      });
  }
}
