<app--authorized-base pageWrapperClasses="welcome-page" headerClasses="!drop-shadow-none">
  <app-content-card>
    <app-page-title label="{{company?.name ?? groupName ?? 'Moje rabaty' }}" routerLinkUrl="/brands" />
  <div *ngIf="company" class="mt-10">
    <img src="/assets/{{company.logo.filePath}}" alt="" class="block mx-auto mb-20" />
  </div>
  <div class="max-w-[48rem] w-full md:px-8">
    <section *ngIf="company" id="brand-page__text-intro">
    </section>
    <section id="brand-page__stores" class="flex flex-col md:flex-row items-center justify-start gap-x-5 gap-y-5 my-8">
      <a href="javascript:void(0)" (click)="this.showMap = !this.showMap" class="btn btn-secondary gap-x-2.5 px-7">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.81 27.675" class="w-5 fill-brand-medium">
          <path d="M11.689,27.053c2.78-3.479,9.121-11.912,9.121-16.648a10.405,10.405,0,0,0-20.81,0c0,4.736,6.341,13.169,9.121,16.648a1.637,1.637,0,0,0,2.569,0ZM10.405,6.937a3.468,3.468,0,1,1-3.468,3.468A3.468,3.468,0,0,1,10.405,6.937Z" />
        </svg>
        <span>lokalizacje sklepów</span>
      </a>

      <a *ngIf="company" target="_blank" href="{{company.www}}" class="btn btn-secondary rounded-full bg-gradient-to-b from-brand-dark to-brand-light !text-white text-center px-7">
        sklep internetowy
      </a>
    </section>
    <app-map *ngIf="this.showMap" [markers]="this.markers" />
    <section class="flex flex-col" *ngIf="this.campaigns">
      <h3 class="font-bold text-lg text-gray-dark mb-5">Aktualne promocje w {{company?.name}}</h3>
      <app-slider [slidePxGap]="24" [slidePxWidth]="365" [slidesPerPage]="2" [stretchGap]="false" class="-mx-4 md:-mx-[4.5rem] -mb-9">
        @for ( i of this.campaigns; track $index) {
          <app-slide>
            <app-coupon [mode]="i.pointCost ? CouponValueMode.Price: CouponValueMode.Percent" [value]="i.campaign.percent" [descriptionText]="i.campaign.longText">
              {{i.campaign.shortText}}
              <div class="text-right z-30 mt-5" *ngIf="i.file">
                <a *ngIf="this.deviceService.isDesktop();" style="text-decoration-line: underline !important;" (click)="openDialog(i.file.fileNameSlug ?? i.file.id, $event)" href="javascript:void(0)" class="text-gray-dark text-xs">Regulamin</a>
                <a *ngIf="this.deviceService.isTablet() || this.deviceService.isMobile()" style="text-decoration-line: underline !important;" target="_blank" [href]="environment.apiUrl + '/files/' + (i.file.fileNameSlug ?? i.file.id)" class="text-gray-dark text-xs">Regulamin</a>
              </div>
            </app-coupon>
          </app-slide>
        }
      </app-slider>
    </section>
  </div>
  </app-content-card>
</app--authorized-base>
