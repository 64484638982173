<app--unauthorized-base>
  <div class="text-gray-dark">
  <p style="text-align: center;line-height: 100%;margin-bottom: 1.46cm;background: rgb(255, 255, 255);"><span style="">REGULAMIN PROGRAMU LOJALNOŚCIOWEGO KLUB QUIOSQUE</span></p>
  <p style="line-height: 100%;text-align: left;margin-bottom: 0.48cm;background: #ffffff;"><span style="color: rgb(246, 153, 164);"><strong>POSTANOWIENIA OG&Oacute;LNE</strong></span></p>
  <ol class="list-decimal">
    <li>
      <p style="text-align: justify;line-height: 100%;margin-bottom: 0.26cm;background: rgb(255, 255, 255);"><span style="">Niniejszy Regulamin zawiera warunki uczestnictwa w programie lojalnościowym Quiosque Klub QUIOSQUE (zwanego w dalszej części Regulaminu &bdquo;Programem&rdquo; lub &ldquo;Klubem QUIOSQUE&rdquo;) i obowiązuje Organizatora oraz Uczestnik&oacute;w od chwili ogłoszenia i przystąpienia do Programu przez Uczestnika. Rejestracja do Programu oznacza akceptację niniejszego Regulaminu.</span></p>
    </li>
    <li>
      <p style="text-align: justify;line-height: 100%;margin-bottom: 0.26cm;background: rgb(255, 255, 255);"><span style="">Organizatorem Programu jest firma PBH S.A., z siedzibą w Bydgoszczy, przy pl. Kościeleckich 3 , 85-033, wpisaną do rejestru przedsiębiorc&oacute;w prowadzonego przez Sąd Rejonowy w Bydgoszczy, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000027965, wysokość kapitału zakładowego: 6.108.921,00 zł (wpłacony w całości), NIP: 5540233443, REGON: 090009778.</span></p>
    </li>
    <li>
      <p style="text-align: justify;line-height: 100%;margin-bottom: 0.26cm;background: rgb(255, 255, 255);"><span style="">Promocja skierowana jest do klient&oacute;w (dalej: &quot;Uczestnicy&quot;), dokonujących zakup&oacute;w towar&oacute;w i usług w punktach sprzedaży sieci sklep&oacute;w Quiosque na terytorium Rzeczypospolitej Polskiej oraz w sklepie internetowym Organizatora na stronie www.quiosque.pl.</span></p>
    </li>
    <li>
      <p style="text-align: justify;line-height: 100%;margin-bottom: 0.26cm;background: rgb(255, 255, 255);"><span style="">Do Programu może przystąpić każda osoba fizyczna, kt&oacute;ra ukończyła 18 lat i posiada pełną zdolność do czynności prawnych.</span></p>
    </li>
    <li>
      <p style="text-align: justify;line-height: 100%;margin-bottom: 0.26cm;background: rgb(255, 255, 255);"><span style="">Regulamin obowiązuje Organizatora i Uczestnik&oacute;w od chwili jego ogłoszenia.</span></p>
    </li>
  </ol>
  <p style="line-height: 100%;text-align: left;margin-bottom: 0.48cm;background: #ffffff;"><span style="color: rgb(246, 153, 164);"><strong>UCZESTNICTWO W PROGRAMIE</strong></span></p>
  <ol class="list-decimal">
    <li>
      <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;"><span style="">Warunkiem członkostwa w Programie jest akceptacja Regulaminu, prawidłowe wypełnienie formularza zgłoszeniowego w aplikacji Klubu QUIOSQUE.</span></p>
    </li>
    <li>
      <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;"><span style="">Prawidłowo wypełniony formularz musi zawierać wszystkie wymagane dane, tj.: imię, nazwisko, numer telefonu i adres e-mail, data urodzinia oraz miasto zamieszkania wraz z ulicą i kodem pocztowym Uczestnika.</span></p>
    </li>
    <li>
      <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;"><span style="">Uczestnik akceptuje treść Regulaminu poprzez potwierdzenie założenia konta w Klubie QUIOSQUE. Brak potwierdzenia skutkuje brakiem możliwości dołączenia do Klubu QUIOSQUE.</span></p>
    </li>
    <li>
      <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;"><span style="">Uczestnik może dodatkowo wyrazić zgodę na przetwarzanie jego danych osobowych w celu przesyłania informacji handlowych oraz w celu dokonania profilowania na zasadach wskazanych w udzielanej zgodzie oraz w obowiązku informacyjnym.</span></p>
    </li>
    <li>
      <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;"><span style="">Członkostwo w Programie powstaje z chwilą przyjęcia poprawnie wypełnionego formularza i potwierdzeniu dołączenia, a także otrzymania elektronicznej karty Klubu QUIOSQUE.<br>&nbsp;</span><br>&nbsp;</p>
    </li>
    <li>
      <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;"><span style="">Uczestnik zobowiązany jest zawiadomić Organizatora o każdej zmianie swoich danych osobowych. W przypadku braku takiego zawiadomienia bądź braku aktualizacji danych, Organizator nie ponosi odpowiedzialności za op&oacute;źnienie lub brak możliwości korzystania z przywilej&oacute;w Klubu QUIOSQUE, jakie przysługują Uczestnikowi, kt&oacute;ry był spowodowany brakiem aktualnych danych kontaktowych. Uczestnik może zmienić sam swoje dane za pośrednictwem aplikacji klubu QUIOSQUE lub dokonując zgłoszenia na&nbsp;</span><span style="color: rgb(5, 99, 193);"><u><a href="mailto:klub@quiosque.pl">klub&#64;quiosque.pl</a></u></span></p>
    </li>
    <li>
      <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;"><span style="">Zakończenie uczestnictwa w Programie (Wykreślenie Uczestnika z bazy danych) może odbyć się na wniosek Uczestnika lub Organizatora.</span></p>
    </li>
  </ol>
  <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;margin-left: 1.27cm;"><span style="">Zakończenie uczestnictwa w Programie następuje na pisemny wniosek Uczestnika złożony w jednej z poniższych form: samodzielnie w aplikacji klubu lub w formie elektronicznej, poprzez jego przesłanie na adres e-mail:&nbsp;</span><span style="color: rgb(5, 99, 193);"><u><a href="mailto:klub@quiosque.pl">klub&#64;quiosque.pl</a></u></span><span style="">&nbsp;</span></p>
  <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;margin-left: 1.27cm;"><span style="">W przekazanym wniosku należy uwzględnić następujące informacje: a. prośbę o usunięcie danych z systemu b. informację o tym, że osoba jest świadoma utraty przywilej&oacute;w wynikających z uczestnictwa w Klubie QUIOSQUE c. imię, nazwisko i numer karty Klubu QUIOSQUE Uczestnika składającego rezygnację d. adres e-mail i numer telefonu Uczestnika składającego rezygnację. Dane potrzebne są do weryfikacji Uczestnika w systemie w celu usunięcia z niego właściwej osoby.<br>&nbsp;Uczestnik może zostać usunięty z Programu przez Organizatora, jeśli dopuści się: a. złamania Regulaminu Programu b. złamania Regulaminu Promocji organizowanej w ramach Klubu QUIOSQUE c. gdy Organizator zidentyfikuje rażące nadużycia przywilej&oacute;w dokonane przez Użytkownika podczas uczestnictwa w Programie.</span></p>
  <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;margin-left: 1.27cm;"><br>&nbsp;</p>
  <p style="line-height: 100%;text-align: left;margin-bottom: 0.48cm;background: #ffffff;"><span style="color: rgb(246, 153, 164);"><strong>KORZYŚCI KLUBU QUIOSQUE I PRZYWILEJE UCZESTNIKA</strong></span></p>
  <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;margin-left: 1.27cm;"><br>&nbsp;</p>
  <ol class="list-decimal">
    <li>
      <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;"><span style="">Po przystąpieniu do Programu Uczestnik jest uprawniony do otrzymania jednorazowego rabatu powitalnego &ndash; 15 % z możliwością jego realizacji w ciągu 30 dni licząc od dnia następnego po dacie wydania i nie obejmuje rabatem zakupu rajstop, bielizny, piżam, stroj&oacute;w kąpielowych.</span></p>
    </li>
    <li>
      <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;"><span style="">Po przystąpieniu do Programu Uczestnik ma możliwość dokonywania zwrot&oacute;w towar&oacute;w zakupionych w sklepach QUIOSQUE (stacjonarnych lub sklepie internetowym www.quiosque.pl) bez konieczności okazania paragonu w formie papierowej lub innego dowodu potwierdzającego prawo dokonania zwrotu. Przywilej ten jest aktualny dla transakcji dokonanych z Kartą QUIOSQUE. Termin zwrotu dla Klient&oacute;w w Klubie QUIOSQUE to 30 dni.&nbsp;</span></p>
    </li>
    <li>
      <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;"><span style="">Po przystąpieniu do Programu Uczestnik ma możliwość dokonywania reklamacji towar&oacute;w zakupionych w sklepach QUIOSQUE (stacjonarnych lub w sklepie internetowym www.quiosque.pl) bez konieczności okazania paragonu w formie papierowej lub innego dowodu potwierdzającego prawo do złożenia reklamacji. Korzyść ta jest aktualna dla transakcji dokonanych z Kartą QUIOSQUE.<br>&nbsp;</span><br>&nbsp;</p>
    </li>
    <li>
      <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;"><span style="">Po przystąpieniu do Programu, jeżeli wyrażono zgodę, Uczestnik będzie otrzymywał promocje personalizowane, tj. dostosowane do swoich indywidualnych preferencji i zainteresowań zakupowych na podstawie informacji o aktywności konsumenckiej Uczestnika w Programie (tzw. profilowanie).</span></p>
    </li>
    <li>
      <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;"><span style="">Po przystąpieniu do Programu Uczestnik może brać udział w akcjach promocyjnych dedykowanych tylko dla Uczestnik&oacute;w Programu oraz skorzystać z darmowej dostawy na zakupy na&nbsp;</span><span style="color: rgb(5, 99, 193);"><u><a href="http://www.quiosque.pl/">www.quiosque.pl</a></u></span><span style="">&nbsp;po podaniu numeru swojej wirtualnej karty Klubu QUIOSQUE.</span></p>
    </li>
  </ol>
  <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;margin-left: 0.64cm;"><br>&nbsp;</p>
  <ol start="6">
    <li>
      <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;"><span style="">Po przystąpieniu do Programu Uczestnik automatycznie bierze udział w zbieraniu punkt&oacute;w. Każda wydana 1 złot&oacute;wka z Klubem QUIOSQUE to 1 punkt.&nbsp;</span></p>
    </li>
  </ol>
  <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;margin-left: 1.27cm;"><span style="">&bull;1000 punkt&oacute;w&ndash; 50 zł jednorazowego rabatu&nbsp;</span></p>
  <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;margin-left: 1.27cm;"><span style="">&bull;1500 punkt&oacute;w &ndash; 100 zł jednorazowego rabatu&nbsp;</span></p>
  <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;margin-left: 1.27cm;"><span style="">&bull;2000 punkt&oacute;w&ndash; 200 zł jednorazowego rabatu</span></p>
  <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;margin-left: 1.27cm;"><span style=""><br>&nbsp;Aktualny rabat dostępny jest na koncie klienta. Rabat ten nie łączy się z rabatem urodzinowym.<br>&nbsp;<br>&nbsp;</span><br>&nbsp;</p>
  <ol start="7">
    <li>
      <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;"><span style="">Każdy Uczestnik Klubu QUIOSQUE otrzymuje stały rabat w wysokości &ndash; 10 % na nową kolekcję po zeskanowaniu wirtualnej karty Klubu QUIOSQUE podczas zakup&oacute;w. Warunkiem utrzymania rabatu jest dokonanie zakup&oacute;w na kwotę min. 3000 zł w ciągu roku.<br>&nbsp;<br>&nbsp;</span><br>&nbsp;</p>
    </li>
    <li>
      <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;"><span style="">Rabat urodzinowy &ndash; 30 % na cały koszyk jest dostępny w wirtualnym koncie Klubu QUIOSQUE przez 3 dni robocze od daty urodzin osoby posiadającej wirtualną kartę Klubu QUIOSQUE. Rabat ten jest jednorazowy i nie łączy się z innymi promocjami oraz rabatami. Rabat jest naliczany od ceny pierwszej.<br>&nbsp;</span><br>&nbsp;</p>
    </li>
    <li>
      <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;"><span style="">Dostęp do innych promocji dedykowanych Klubowiczkom&nbsp;</span></p>
    </li>
  </ol>
  <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;"><span style=""><br>&nbsp;</span><br>&nbsp;</p>
  <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;margin-left: 1.27cm;"><br>&nbsp;</p>
  <p style="line-height: 100%;text-align: left;margin-bottom: 0.48cm;background: #ffffff;"><span style="color: rgb(246, 153, 164);"><strong>POSTANOWIENIA KOŃCOWE</strong></span></p>
  <ol class="list-decimal">
    <li>
      <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;"><span style="">Niniejszy regulamin obowiązuje od dnia DATA</span></p>
    </li>
    <li>
      <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;"><span style="">Organizator zastrzega sobie prawo zmian niniejszego Regulaminu w każdym czasie, w przypadku: a. zmiany przedmiotu działalności Organizatora, b. konieczności dostosowania treści Regulaminu do brzmienia obowiązujących przepis&oacute;w prawa c. wprowadzenia nowych korzyści wynikających z uczestnictwa w Klubie QUIOSQUE d. ograniczenia lub wycofania korzyści wynikających z uczestnictwa w Klubie QUIOSQUE w wyniku wystąpienia po stronie Organizatora poważnych problem&oacute;w finansowych lub organizacyjnych, istotnie utrudniających lub uniemożliwiających dalsze udostępnianie korzyści. Organizator zawiadomi Uczestnika o dokonanych zmianach w Regulaminie nie p&oacute;źniej niż na 14 dni przed wejściem zmian w życie, poprzez udostępnienie zmienionego tekstu Regulaminu w sklepach QUIOSQUE oraz na stronie internetowej www.quiosque.pl lub wyśle go za pomocą poczty elektronicznej na adres e-mail wskazany przez Uczestnika.</span></p>
    </li>
    <li>
      <p style="line-height: 100%;text-align: left;margin-bottom: 0.26cm;background: #ffffff;"><span style="">Wszelkie zmiany Regulaminu wchodzą w życie w terminie określonym przez Organizatora.</span></p>
    </li>
  </ol>
  </div>
</app--unauthorized-base>
