import { Component } from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {PageTitleComponent} from '../page-title/page-title.component';
import {RouterLink} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ContentCardComponent} from '../../common/content-card/content-card.component';

@Component({
  selector: 'app-benefits',
  standalone: true,
  imports: [
    BaseComponent,
    PageTitleComponent,
    RouterLink,
    ContentCardComponent
  ],
  templateUrl: './benefits.component.html',
  styleUrl: './benefits.component.css'
})
export class BenefitsComponent {

  constructor(private spinnerService: NgxSpinnerService) {
  }

  ngOnInit() {
    setTimeout(()=>{
      this.spinnerService.hide('main');
    });
  }
}
