import { Component } from '@angular/core';
import {Location} from '@angular/common';
import {ThemingComponent} from '../../common/theming/theming.component';
import {UpdateAppService} from '../../_services/update-app.services';

@Component({
  selector: 'app--unauthorized-base',
  templateUrl: './base.component.html',
  standalone: true,
  imports: [
    ThemingComponent
  ],
  styleUrl: './base.component.css'
})
export class BaseComponent {
  constructor(
    private location: Location,
    private updateService: UpdateAppService
  ) {

    this.updateService.checkForUpdates();
  }
  goBack() {

    this.location.back();
  }
}
