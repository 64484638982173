import { Component } from '@angular/core';
import {BaseComponent} from '../../unauthorized/base/base.component';

@Component({
  selector: 'app-terms-of-service',
  standalone: true,
  imports: [
    BaseComponent
  ],
  templateUrl: './terms-of-service.component.html',
  styleUrl: './terms-of-service.component.css'
})
export class TermsOfServiceComponent {

}
