import {Component, ElementRef, OnInit, viewChild} from '@angular/core';
import {BaseComponent} from "../base/base.component";
import {CheckboxComponent} from '../../common/checkbox/checkbox.component';
import {AgreementComponent} from '../../common/checkbox/agreement/agreement.component';
import {Router, RouterLink} from '@angular/router';
import {
  FormBuilder,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {FormControlTextFieldComponent} from '../../common/form-control-text-field/form-control-text-field.component';
import {FormControlDateFieldComponent} from '../../common/form-control-date-field/form-control-date-field.component';
import {CustomerServices} from '../../_services/customer.services';
import {
  GooglePlacesAutocompleteComponent
} from '../../common/google-places-autocomplete/google-places-autocomplete.component';
import {ValidatorsCustom} from '../../_utils/validators-custom.service';
import {ConsentsServices} from '../../_services/consents.services';
import {NgForOf, NgIf} from '@angular/common';
import {AuthServices} from '../../_services/auth.services';
import {MeResponse} from '../../_interfaces/me.response.interface';

interface KeyValue {
  [key: string]: boolean;
}

@Component({
  selector: 'app-finalize',
  standalone: true,
  imports: [
    BaseComponent,
    CheckboxComponent,
    AgreementComponent,
    ReactiveFormsModule,
    FormControlTextFieldComponent,
    FormControlDateFieldComponent,
    RouterLink,
    GooglePlacesAutocompleteComponent,
    NgIf,
    NgForOf
  ],
  templateUrl: './finalize.component.html',
  styleUrl: './finalize.component.css'
})
export class FinalizeComponent implements OnInit {
  protected submitButton = viewChild<ElementRef>('submitButton');
  protected postCodeEl!: HTMLInputElement;
  protected cityEl!: HTMLInputElement;
  public finalizeForm: any;
  public postCodeError: string = '';
  public birthDateError: string = '';
  public customerId: string = '';
  public streetError: string = '';
  public consents: any;
  public checked: KeyValue = {};
  public showEmail = false;
  public showPhoneNumber = false;
  public customer!: MeResponse;
  public trialEnded: string = '0';
  public phoneControlError: string = '';

  constructor(
    formBuilder: FormBuilder,
    private router: Router,
    private customerService: CustomerServices,
    private consentsService: ConsentsServices,
    private authServices: AuthServices
  ) {
    this.finalizeForm = formBuilder.group({
      street: ['', [Validators.required, Validators.pattern('^.*\\w+\\s\\d+.*$')]],
      postCode: ['', [Validators.required, Validators.pattern('^\\d{2}-\\d{3}$')]],
      city: ['', Validators.required],
      birthDate: ['', [Validators.required, ValidatorsCustom.adultOnly()]],
      email: ['', []],
      phoneNumber: ['', []]
    });
    this.finalizeForm.valueChanges.subscribe({
      next: (value: string) => {
        this.validation()
      }
    })
  }

  ngAfterViewInit() {
    this.postCodeEl = document.getElementById('postCode') as HTMLInputElement;
    this.cityEl = document.getElementById('city') as HTMLInputElement;

    this.finalizeForm.controls['street'].valueChanges.subscribe({
      next: () => {
        this.postCodeEl.removeAttribute('disabled');
        this.cityEl.removeAttribute('disabled');
      }
    })
  }

  ngOnInit(): void {
    this.customerId = sessionStorage.getItem('customerId') ?? '';
    if (this.customerId === '') {
      this.router.navigate(['register']);
    } else {
      this.trialEnded = sessionStorage.getItem('trialEnded') ?? '0';
      this.customerService.getMe(sessionStorage.getItem('token') ?? '').subscribe({
        next: (data) => {
          this.customer = data;
          this.showEmail = data.email === '' || data.email === null;
          this.showPhoneNumber = data.phoneNumber === null;
          if (this.showEmail) {
            this.finalizeForm.controls['email'].setValidators([Validators.required, Validators.email]);
            this.finalizeForm.controls['email'].updateValueAndValidity()
          }
          if (this.showPhoneNumber) {
            this.finalizeForm.controls['phoneNumber'].setValidators([Validators.required, Validators.pattern('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{5}$')]);
            this.finalizeForm.controls['phoneNumber'].updateValueAndValidity()
          }
        }
      });
    }
  }

  validation() {
    let postCodeErrors = this.finalizeForm.controls['postCode'].errors;
    let birthCodeErrors = this.finalizeForm.controls['birthDate'].errors;
    let streetErrors = this.finalizeForm.controls['street'].errors;
    let phoneErrors = this.finalizeForm.controls['phoneNumber'].errors;

    if (postCodeErrors?.required === true) {
      this.postCodeError = "Kod pocztowy jest wymagany jest wymagany"
    }
    if (postCodeErrors?.pattern instanceof Object) {
      this.postCodeError = "To nie jest poprawny kod pocztowy, przykładowy: 62-200"
    }
    if (birthCodeErrors?.required === true) {
      this.birthDateError = "Data urodzenia jest wymagana"
    }
    if (birthCodeErrors?.needAdult === true) {
      this.birthDateError = "Tylko osoba która ukończyła 18 lat może być członkiem klubu"
    }
    if (streetErrors?.required === true) {
      this.streetError = "Ulica jest wymagana"
    }
    if (streetErrors?.pattern) {
      this.streetError = "Należy podać ulicę wraz z numerem domu"
    }
    if (postCodeErrors === null) {
      this.postCodeError = "";
    }
    if (birthCodeErrors === null) {
      this.birthDateError = "";
    }
    if (streetErrors === null) {
      this.streetError = "";
    }

    if (this.showPhoneNumber) {
      if (phoneErrors?.required === true) {
        this.phoneControlError = "Numer telefonu jest wymagany"
      }
      if (phoneErrors?.pattern instanceof Object) {
        this.phoneControlError = "To nie jest poprawny numer telefonu komórkowego, przykładowy: +48501501501"
      }
      if (phoneErrors === null) {
        this.phoneControlError = "";
      }
    }
  }

  setAddressFromGooglePlace(place: google.maps.places.PlaceResult) {
    const streetName = GooglePlacesAutocompleteComponent.findAddressComponent(place, 'route') ?? "";
    const streetNumber = GooglePlacesAutocompleteComponent.findAddressComponent(place, 'street_number') ?? "";
    this.finalizeForm.controls['street'].setValue(streetName + " " + streetNumber);
    this.finalizeForm.controls['street'].markAsTouched();
    this.finalizeForm.controls['street'].markAsDirty();

    this.finalizeForm.controls['postCode'].setValue(GooglePlacesAutocompleteComponent.findAddressComponent(place, 'postal_code'));
    this.finalizeForm.controls['postCode'].markAsTouched();
    this.finalizeForm.controls['postCode'].markAsDirty();
    this.postCodeEl.setAttribute("disabled", '');

    this.finalizeForm.controls['city'].setValue(GooglePlacesAutocompleteComponent.findAddressComponent(place, 'locality'));
    this.finalizeForm.controls['city'].markAsTouched();
    this.finalizeForm.controls['city'].markAsDirty();
    this.cityEl.setAttribute("disabled", '');
  }

  submit(event: SubmitEvent) {
    event.preventDefault();
    for (const i in this.finalizeForm.controls) {
      this.finalizeForm.controls[i].markAsTouched();
      this.finalizeForm.controls[i].markAsDirty();
      this.validation()
    }

    if (this.finalizeForm.status === 'VALID') {
      this.submitButton()!.nativeElement.classList.toggle('opacity-30');
      let emailOrPhone = '';
      if (this.finalizeForm.value.email !== '') {
        emailOrPhone = this.finalizeForm.value.email;
      } else if (this.finalizeForm.value.phoneNumber !== '') {
        emailOrPhone = this.finalizeForm.value.phoneNumber;
      }
      if (emailOrPhone !== '') {
        this.authServices.sendAuthCode({
          customerId: this.customer.id,
          emailOrPhone: emailOrPhone,
        }).subscribe({
          next: (data) => {
            if (this.finalizeForm.value.email !== '') {
              sessionStorage.setItem('registration', JSON.stringify({email: this.finalizeForm.value.email}));
            } else if (this.finalizeForm.value.phoneNumber !== '') {
              sessionStorage.setItem('registration', JSON.stringify({phone: this.finalizeForm.value.phoneNumber}));
            }
            sessionStorage.setItem('finalize', JSON.stringify(this.finalizeForm.value));
            this.router.navigate(['register/auth-code']);
          }
        })
      } else {
        this.customerService.patchCustomer(this.finalizeForm.value, this.customerId, sessionStorage.getItem('token') ?? '').subscribe({
          next: (data) => {
            this.submitButton()!.nativeElement.classList.toggle('opacity-30');
            localStorage.setItem('token', sessionStorage.getItem('token') ?? '');
            this.router.navigate(['register/finished']);
          }
        });
      }
    }
  }
}
