import { Component } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ContentCardComponent} from '../../../common/content-card/content-card.component';
import {PageTitleComponent} from '../../page-title/page-title.component';
import {MapComponentComponent} from '../../../common/map-component/map-component.component';
import {ShopServices} from '../../../_services/shop.services';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-shops',
  standalone: true,
  imports: [
    BaseComponent,
    ContentCardComponent,
    PageTitleComponent,
    MapComponentComponent
  ],
  templateUrl: './shops.component.html',
  styleUrl: './shops.component.css'
})
export class ShopsComponent {

  public markers:any = [];

  constructor(private shopServices: ShopServices, private spinnerService: NgxSpinnerService) {
  }

  ngOnInit() {
    let localMarkers:any = [];
    this.shopServices.getShops().subscribe({
      next: data => {
        if (data && data.length > 0) {
          data.forEach(shop => {
            const marker = {
              position: {lat: parseFloat(shop.lat), lng: parseFloat(shop.lng)},
              title: shop.name,
              options: {},
              shop: shop,
            };
            localMarkers.push(marker);
          });
          this.markers = localMarkers;
          this.spinnerService.hide('main');
        }
      }
    })
  }
}
