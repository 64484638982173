import {Component, ElementRef, EventEmitter, Input, Output, Renderer2} from '@angular/core';
import {BaseComponent} from '../../unauthorized/base/base.component';
import {ScriptService} from '../../_services/script.service';
import {FormControlTextFieldComponent} from '../form-control-text-field/form-control-text-field.component';
import {FormControl} from '@angular/forms';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-google-places-autocomplete',
  standalone: true,
  imports: [
    BaseComponent,
    FormControlTextFieldComponent
  ],
  templateUrl: './google-places-autocomplete.component.html',
  styleUrl: './google-places-autocomplete.component.css'
})
export class GooglePlacesAutocompleteComponent {
  @Input({required: true}) name = '';
  @Input({required: true}) label = '';
  // @ts-ignore
  @Input({required: true}) control: FormControl;
  @Input({required: false}) controlError = '';

  protected googleApi: typeof google.maps | undefined;
  @Output() choosePlaceEvent = new EventEmitter<google.maps.places.PlaceResult>();

  constructor(private renderer: Renderer2, private scriptService: ScriptService) {
  }

  public static findAddressComponent(place: google.maps.places.PlaceResult, componentCode: string) {
    return place.address_components!.find((component) => {
      return component.types.includes(componentCode);
    })?.long_name;
  }

  async ngOnInit() {
    this.googleApi = google.maps;
    const { Autocomplete } = await this.googleApi.importLibrary("places") as google.maps.PlacesLibrary;
    const searchBox = document.getElementById(this.name) as HTMLInputElement;
    const autocomplete = new Autocomplete(searchBox, {
      types: ['street_address'],
      componentRestrictions: {
        country: "PL"
      },
      fields: ['address_components']
    });

    autocomplete.addListener('place_changed', () => {
      this.choosePlaceEvent.emit(autocomplete.getPlace());
    })
  };
}
