import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { environment} from '../../environments/environment';
import {HeaderUtils} from '../_utils/HeaderUtils';
import * as constans from '../_constants/constants';
import {MeResponse} from '../_interfaces/me.response.interface';
import {Customer} from '../classes/customer';
import {jwtDecode} from 'jwt-decode';
import {CustomJwtPayload} from '../_interfaces/custom.jwt.payload';
import {UserTokenInterface} from '../_interfaces/user-token.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomerServices {
  private customer: Customer = new Customer();
  private authenticated: boolean = false;

  constructor(private http: HttpClient) {}

  public patchCustomer(data: any, customerId: string, token: string = '') {
    if (token === '') {
      token = localStorage.getItem('token') ?? '';
    }
    return this.http.patch(environment.apiUrl + '/customers/' + customerId, data, {
      headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.PATCH, token),
    });
  }

  public getMe(token: string = '') {
    if (token === '') {
      token = localStorage.getItem('token') ?? '';
    }
    return this.http.get<MeResponse>(environment.apiUrl + '/me' , {
      headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.GET, token),
    });
  }

  public getCustomer(): Customer {
    return this.customer;
  }

  public loginWithToken(token: string): boolean {
    if (this.customer.id === '') {
      this.updateToken(token);
    }

    return this.customer.id !== '';
  }

  public updateToken(token: string) {
    this.authenticated = true;

    let customer = new Customer();
    let local = jwtDecode(token) as CustomJwtPayload;

    customer.id = local.id;
    customer.name = local.name;
    customer.email = local.email;
    customer.clubId = local.clubId;
    customer.points = local.points;
    this.customer = customer;
  }

  getCustomers(value :string) {
    if (value.includes('@')) {
      return this.http.get(environment.apiHost + '/users/customers?email=' + encodeURIComponent(value), {
        headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.GET, localStorage.getItem('user_token') ?? ''),
      });
    } else if(value.includes('+')) {
      return this.http.get(environment.apiHost + '/users/customers?phoneNumber=' + encodeURIComponent(value), {
        headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.GET, localStorage.getItem('user_token') ?? ''),
      });
    } else {
      return this.http.get(environment.apiHost + '/users/customers?clubId=' + encodeURIComponent(value), {
        headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.GET, localStorage.getItem('user_token') ?? ''),
      });
    }
  }

  postInvite(data: any) {
    return this.http.post(environment.apiUrl + '/invite', data, {
      headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.POST, localStorage.getItem('token') ?? ''),
    });
  }

  getDeleteToken() {
    return this.http.get(environment.apiUrl + '/delete-token', {
      headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.POST, localStorage.getItem('token') ?? ''),
    });
  }

  postDelete(pin:string) {
    return this.http.post(environment.apiUrl + '/delete', {pin: pin}, {
      headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.POST, localStorage.getItem('token') ?? ''),
    });
  }

  postSaleAction(userOrCustomer:UserTokenInterface, data:any) {
    return this.http.post(environment.apiHost + '/users/sale_actions', data, {
      headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.POST, userOrCustomer.getToken() ?? ''),
    });
  }

  refreshToken() {
    return this.http.get(environment.apiUrl + '/refresh_token', {
      headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.GET, localStorage.getItem('token') ?? ''),
    });
  }
}
