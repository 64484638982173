import {Component, OnInit} from '@angular/core';
import {ContentCardComponent} from '../../common/content-card/content-card.component';
import {RouterLink} from '@angular/router';
import {AuthServices} from '../../_services/auth.services';
import {BaseComponent} from '../../unauthorized/base/base.component';

@Component({
  selector: 'app-delete-account-success',
  standalone: true,
  imports: [
    BaseComponent,
    ContentCardComponent,
    RouterLink,
    BaseComponent
  ],
  templateUrl: './delete-account-success.component.html',
  styleUrl: './delete-account-success.component.css'
})
export class DeleteAccountSuccessComponent implements OnInit {

  constructor(private authService:AuthServices) { }

  ngOnInit(): void {
    setTimeout( ()=>{
      this.authService.removeToken();
    }, 1000)
  }
}
