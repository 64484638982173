import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {HeaderUtils} from '../_utils/HeaderUtils';
import {CampaignResponse} from '../_interfaces/campaign.response.interface';
import {map, Observable} from 'rxjs';
import {Campaign} from '../_interfaces/campaign.interface';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  constructor(private httpClient: HttpClient) {
  }

  getCampaign(id: string): Observable<Campaign> {
    return this.httpClient.get<Campaign>(environment.apiUrl + '/campaigns/' + id, {
      headers: HeaderUtils.createHeaders('GET', localStorage.getItem('token') ?? ''),
    });
  }

  getCampaigns(useToken: boolean = false, group: string = '', pointCost = false, company: string = ''): Observable<CampaignResponse[]> {
    let queryUrl = '';
    if (group !== '') {
      queryUrl = queryUrl + '?campaignGroup.name=' + group;
    }
    if (pointCost) {
      if (queryUrl !== '') {
        queryUrl = queryUrl + '&';
      } else {
        queryUrl = queryUrl + '?';
      }
      queryUrl = queryUrl + 'exists[pointCost]&order[pointCost]=asc';
    }

    if (company) {
      if (queryUrl !== '') {
        queryUrl = queryUrl + '&';
      } else {
        queryUrl = queryUrl + '?';
      }
      queryUrl = queryUrl + 'company.name=' + company;
    }

    if (useToken) {
      return this.httpClient.get<CampaignResponse[]>(environment.apiUrl + '/campaigns' + queryUrl, {
        headers: HeaderUtils.createHeaders('', localStorage.getItem('token') ?? ''),
      }).pipe(
        map((data: CampaignResponse[]) => {
          let tempData: any[] = [];
          for (const tempDatum of data) {
            if (tempDatum.campaign !== undefined) {
              tempData.push(tempDatum);
            }
          }
          return tempData;
        })
      );
    }

    return this.httpClient.get<CampaignResponse[]>(environment.apiUrl + '/campaigns' + queryUrl).pipe(
      map((data: CampaignResponse[]) => {
        let tempData: any[] = [];
        for (const tempDatum of data) {
          if (tempDatum.campaign !== undefined) {
            tempData.push(tempDatum);
          }
        }
        return tempData;
      })
    );
  }

  getCampaignsGroups()
  {
    return this.httpClient.get(environment.apiUrl + '/campaigns_groups', {
      headers: HeaderUtils.createHeaders('GET', localStorage.getItem('token') ?? ''),
    });
  }
}
