import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HeaderUtils} from '../_utils/HeaderUtils';
import * as constans from '../_constants/constants';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FilesServices {
  constructor(private http: HttpClient) {
  }

  downloadFile(file: string) {
    return this.http.get(environment.apiUrl + '/files/' + file, {
      headers: HeaderUtils.createHeaders(constans.HTTP_METHOD.GET, localStorage.getItem('token') ?? ''),
      responseType: 'blob' as 'json'
    });
  }
}
