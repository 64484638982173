import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {PageTitleComponent} from '../page-title/page-title.component';
import {NotificationsService} from '../../_services/notifications.service';
import {NgIf} from '@angular/common';
import {Subject} from 'rxjs';
import {NotificationsResponse} from '../../_interfaces/notifications.response';
import {ContentCardComponent} from '../../common/content-card/content-card.component';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [
    BaseComponent,
    PageTitleComponent,
    NgIf,
    ContentCardComponent
  ],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.css'
})
export class NotificationsComponent implements OnInit {
  protected readonly Array = Array;
  public data!: NotificationsResponse;
  private interval: any;

  constructor(private notificationsService: NotificationsService, private spinnerService: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.notificationsService.data$.subscribe(data => { // subscribe once to the data stream
      this.data = data;
      this.spinnerService.hide('main');
    });
  }

  read(id: string) {
    this.notificationsService.unread(id).subscribe();
  }
}
