import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {SliderComponent} from '../../../common/slider/slider.component';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {SlideComponent} from '../../../common/slider/slide/slide.component';
import {CompanyServices} from '../../../_services/company.services';
import {CompaniesResponse} from '../../../_interfaces/companies.response.interface';
import {NgIf} from '@angular/common';
import {ShopServices} from '../../../_services/shop.services';
import {MapComponentComponent} from '../../../common/map-component/map-component.component';
import {PageTitleComponent} from "../../page-title/page-title.component";
import {CouponComponent, CouponValueMode} from '../../../common/coupon/coupon.component';
import {CampaignsService} from '../../../_services/campaigns.service';
import {CampaignResponse} from '../../../_interfaces/campaign.response.interface';
import {ContentCardComponent} from '../../../common/content-card/content-card.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {FilesServices} from '../../../_services/files.services';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../../environments/environment';
import {IframeDialogComponent} from '../../../common/iframe-dialog/iframe-dialog.component';
import {DeviceDetectorService, DeviceInfo} from 'ngx-device-detector';

@Component({
  selector: 'app-brand-page',
  standalone: true,
  imports: [
    BaseComponent,
    SliderComponent,
    SlideComponent,
    NgIf,
    MapComponentComponent,
    PageTitleComponent,
    CouponComponent,
    ContentCardComponent
  ],
  templateUrl: './brand-page.component.html',
  styleUrl: './brand-page.component.css'
})
export class BrandPageComponent implements OnInit {
  protected readonly Array = Array;
  public company?: CompaniesResponse;
  public markers:any = [];
  public showMap: boolean = false;
  public campaigns!: CampaignResponse[];
  public groupName: string|null = null;

  constructor(
    private companyService: CompanyServices,
    private route: ActivatedRoute,
    private router: Router,
    private shopServices: ShopServices,
    private campaignService: CampaignsService,
    private spinnerService: NgxSpinnerService,
    private fileService: FilesServices,
    public dialog: MatDialog,
    public deviceService: DeviceDetectorService
  ) {
  }

  ngOnInit(): void {
    const selectedName = this.route.snapshot.queryParams['name'];
    const selectedGroup = this.route.snapshot.queryParams['group'];
    let localMarkers:any = [];
    if( selectedName ) {
      this.companyService.getCompanies(null, selectedName).subscribe({
        next: data => {
          if (data && data.length > 0) {
            this.company = data[0];
            this.hideSpinnerIfAllDataIsReady();
            return;
          }
          this.router.navigateByUrl('/brands');
        }
      });
    }
    this.campaignService.getCampaigns(false, selectedGroup, false, selectedName).subscribe({
      next: data => {
        this.campaigns = data;
        if( selectedGroup ) {
          this.groupName = selectedGroup[0].toUpperCase() + selectedGroup.substring(1);
        }
        this.hideSpinnerIfAllDataIsReady();
      }
    })

    this.shopServices.getShops().subscribe({
      next: data => {
        if (data && data.length > 0) {
          data.forEach(shop => {
            const marker = {
              position: {lat: parseFloat(shop.lat), lng: parseFloat(shop.lng)},
              title: shop.name,
              options: {},
              shop: shop,
            };
            localMarkers.push(marker);
          });
          this.markers = localMarkers;
        }
      }
    })
  }

  hideSpinnerIfAllDataIsReady() {
    const name = this.route.snapshot.queryParams['name'];
    if( ( !this.company == !name ) && this.campaigns ) {
      this.spinnerService.hide('main');
    }
  }

    protected readonly CouponValueMode = CouponValueMode;

  openDialog(file: string, $event: MouseEvent) {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(IframeDialogComponent, {
      data: { url: environment.apiUrl + '/files/' + file },
    });
  }

  protected readonly environment = environment;
}
