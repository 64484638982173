import {Component, OnInit} from '@angular/core';
import {Router, RouterLink} from '@angular/router';
import {SliderComponent} from '../../common/slider/slider.component';
import {SlideComponent} from '../../common/slider/slide/slide.component';
import {ThemingComponent} from '../../common/theming/theming.component';
import {ContentCardComponent} from '../../common/content-card/content-card.component';
import {AuthServices} from '../../_services/auth.services';

@Component({
  selector: 'app-index',
  standalone: true,
  imports: [
    RouterLink,
    SliderComponent,
    SlideComponent,
    ThemingComponent,
    ContentCardComponent
  ],
  templateUrl: './index.component.html',
  styleUrl: './index.component.css'
})
export class IndexComponent implements OnInit {

  protected readonly Array = Array;
  protected showBenefits = false;
  protected readonly slides = [
    {
      brand: 'qsq',
      name: 'Quiosque',
      logo: '/assets/images/intro/logos/qsq-white.svg',
      content: '/assets/images/intro/QSQ.png'
    },
    {
      brand: 'akardo',
      name: 'Akardo',
      logo: '/assets/images/intro/logos/akardo-white.svg',
      content: '/assets/images/intro/AKARDO.png'
    },
    {
      brand: 'laurella',
      name: 'Laurella',
      logo: '/assets/images/intro/logos/laurella-white.svg',
      content: '/assets/images/intro/LAURELLA.png'
    },
    {
      brand: 'marasimsim',
      name: 'MaraSimSim',
      logo: '/assets/images/intro/logos/mara-simsim-white.svg',
      content: '/assets/images/intro/MARASIMSIM.png'
    },
  ]

  constructor(private authServices: AuthServices, private router: Router) {}

  public changeTheme(event: any) {
    const changeTheme = new CustomEvent('changeTheme', {
      detail: {
        theme: event[0]
      }
    });

    document.body.dispatchEvent(changeTheme);
  }

  public toggleBenefits() {
    this.showBenefits = !this.showBenefits;
    if( this.showBenefits ) {
      setTimeout(()=>{
        window.addEventListener('click', () => {
          if( this.showBenefits ) {
            this.showBenefits = !this.showBenefits;
          }
        }, {once: true});

      });
    }
  }

  ngOnInit(): void {
    (this.authServices.getToken()) !== null ? this.router.navigateByUrl('welcome') : null;
  }
}
