import {Component, ContentChildren, ElementRef, Input, QueryList, viewChild} from '@angular/core';
import {SlideComponent} from './slide/slide.component';

@Component({
  selector: 'app-slider',
  standalone: true,
  imports: [
    SlideComponent
  ],
  templateUrl: './slider.component.html',
  styleUrl: './slider.component.css',
})
export class SliderComponent {
  protected readonly Array = Array;
  @ContentChildren(SlideComponent) protected slides!: QueryList<SlideComponent>;
  protected sliderWrapper = viewChild<ElementRef>('sliderWrapper');
  protected sliderWrapperEl!: HTMLElement;
  protected currentPage = 0;
  protected pageCount = 0;
  protected touchPosition = 0;
  protected currentTranslate = 0;
  protected calculatedGapWidth = 0;
  protected calculatedSlideWidth = 0;
  protected calculatedSlidesPerPage = 0;
  protected sliderEnabled = false;
  @Input({ required: true }) slidePxGap = 0;
  @Input({ required: true }) slidePxWidth = 0;
  @Input({ required: true }) slidesPerPage = 0;
  @Input() activePageBulletClass = 'bg-brand-medium';
  @Input() stretchGap = true;

  ngOnInit() {
    this.calculatedGapWidth = this.slidePxGap;
    this.calculatedSlidesPerPage = this.slidesPerPage;
    this.sliderWrapperEl = this.sliderWrapper()!.nativeElement;
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.calculateLayout();
      this.applyLayout();
      setTimeout(() => {
        this.toggleWrapperTransition();
        this.sliderWrapperEl.classList.toggle('opacity-0');
      });
    });
  }

  protected centerActivePage(index: number) {
    const centerSlide = Math.floor(((index) * this.calculatedSlidesPerPage) + (this.calculatedSlidesPerPage / 2));
    const offset = (centerSlide * this.calculatedSlideWidth) + (centerSlide * this.calculatedGapWidth);
    let difference;

    if( this.calculatedSlidesPerPage % 2 === 0 ) {
      difference = (this.sliderWrapperEl.offsetWidth / 2) + ( this.calculatedGapWidth / 2 );
    } else {
      difference = ( this.sliderWrapperEl.offsetWidth - this.calculatedSlideWidth ) / 2;
    }

    this.slides.get(centerSlide)?.slideEnteredView();

    this.currentTranslate = difference - offset;

    this.sliderWrapperEl.style.transform = `translateX(${this.currentTranslate}px)`;
  }

  protected calculateLayout() {
      this.calculatedSlideWidth = this.slidePxWidth;
      const freeSpace = this.sliderWrapperEl.offsetWidth - this.calculatedSlidesPerPage * this.calculatedSlideWidth;
      const minFreeSpace = this.slidePxGap * ( this.calculatedSlidesPerPage + 1 );
      const maxFreeSpace = this.calculatedSlideWidth + minFreeSpace + this.slidePxGap;
      if( (freeSpace < minFreeSpace) && this.calculatedSlidesPerPage > 1 ) {
        this.calculatedSlidesPerPage--;
        this.calculateLayout();
        return;
      } else if( freeSpace > maxFreeSpace && this.calculatedSlidesPerPage < this.slidesPerPage ) {
        this.calculatedSlidesPerPage++;
        this.calculateLayout();
        return;
      }

      this.pageCount = Math.ceil(this.slides.length / this.calculatedSlidesPerPage);
      this.sliderEnabled = this.pageCount > 1;

      if( this.stretchGap && this.sliderEnabled ) {
        this.calculatedGapWidth = freeSpace / ( this.calculatedSlidesPerPage + 1 );
      } else if (this.stretchGap && !this.sliderEnabled) {
        this.calculatedGapWidth = this.slidePxGap;
      }
  }

  startSwipe(event: TouchEvent) {
    if( !this.sliderEnabled ) {
      return;
    }

    this.touchPosition = event.changedTouches[0].screenX;
    this.toggleWrapperTransition();
  }

  moveSwipe(event: TouchEvent) {
    if( !this.sliderEnabled ) {
      return;
    }

    const translate = this.currentTranslate + event.changedTouches[0].screenX - this.touchPosition;
    this.sliderWrapperEl.style.transform = `translateX(${translate}px)`;
  }

  endSwipe(event: TouchEvent) {
    if( !this.sliderEnabled ) {
      return;
    }

    this.toggleWrapperTransition();
    const endPos = event.changedTouches[0].screenX;
    if( endPos < this.touchPosition && this.currentPage < (this.pageCount - 1) ) {
      this.currentPage++;
    } else if( endPos > this.touchPosition && this.currentPage > 0 ) {
      this.currentPage--;
    }
    this.centerActivePage(this.currentPage);
  }

  changeActivePage(index: number) {
    this.currentPage = index;
    this.centerActivePage(this.currentPage);
  }

  protected toggleWrapperTransition() {
    this.sliderWrapperEl.classList.toggle('transition-transform');
    this.sliderWrapperEl.classList.toggle('ease-in-out');
    this.sliderWrapperEl.classList.toggle('duration-200');
  }

  resizeWindow() {
    const startSlide = this.currentPage * this.calculatedSlidesPerPage + 1;
    this.calculateLayout();
    this.currentPage = Math.ceil(startSlide / this.calculatedSlidesPerPage) - 1;
    this.applyLayout();
  }

  protected applyLayout() {
    this.slides.forEach((val, i, array) => {
      if( i + 1 !== array.length ) {
        val.gap = this.calculatedGapWidth;
      }
      val.width = this.calculatedSlideWidth;
      return val.content()?.nativeElement!;
    });

    this.centerActivePage(this.currentPage);
  }
}
