<app--unauthorized-base>
  <h1 *ngIf="!this.sendAuthCode" class="text-2xl font-bold mb-1">Zaloguj się</h1>
  <h2 *ngIf="!this.sendAuthCode" class="text-gray-light">Podaj swój numer telefonu / email</h2>

  <form novalidate [formGroup]="this.loginForm" (submit)="submit($event)" class="grow flex flex-col">
    <div *ngIf="!this.sendAuthCode" class="mt-8 flex flex-col gap-y-6">
      <app-form-control-text-field class="md:w-6/12" [setDefault]="false" [control]="this.loginForm.controls['emailOrPhone']" name="emailOrPhone" label="Numer telefonu / email" />
      <small class="text-red-500">{{ this.loginFormError }}</small>
    </div>
    <br />
    <div *ngIf="this.sendAuthCode" class="mt-4 flex flex-col gap-y-6">
      <h2 class="text-2xl font-bold mb-0.5">Wpisz kod weryfikacyjny</h2>
      <h2 class="text-gray-light">Wysłaliśmy Tobie mailem lub sms-em jednorazowy kod weryfikacyjny. Przepisz go poniżej.</h2>
      <div class="grid place-content-center">
        <app-pin-pad [parentComponent]="this" [fullValidation]="this.fullValidation" (pinValue)="this.pinValue = $event" (error)="this.pinPadError = $event"/>
        <small class="text-red-500">{{ this.pinPadError }}</small>
        <p *ngIf="!this.resendAuthCodeStatus" class="text-gray-dark mt-4">Kod nie dotarł? <a href="javascript:void(0)" (click)="resendAuthCode()" class="link-button-underline">wyślij ponownie</a></p>
        <p class="text-gray-dark mt-9"><a *ngIf="this.resendAuthCodeStatus">Kod wysłany ponownie!</a></p>
      </div>
    </div>
    <app-form-alert [text]="this.confirmError" *ngIf="confirmError"></app-form-alert>
    <div class="mt-auto justify-items-center">
    <button #submitButton type="submit" class="btn btn-secondary w-64">dalej</button>
    </div>
  </form>
</app--unauthorized-base>
