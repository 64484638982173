import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {CampaignsService} from '../../_services/campaigns.service';
import {NgForOf, NgIf} from '@angular/common';
import {CodesService} from '../../_services/codes.service';
import {CustomerServices} from '../../_services/customer.services';
import {Customer} from '../../classes/customer';
import JsBarcode from 'jsbarcode';
import {RouterLink} from '@angular/router';
import {ContentCardComponent} from '../../common/content-card/content-card.component';
import {SliderComponent} from '../../common/slider/slider.component';
import {SlideComponent} from '../../common/slider/slide/slide.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {CouponComponent, CouponValueMode} from '../../common/coupon/coupon.component';
import {forkJoin} from 'rxjs';
import {CacheService} from '../../_services/cache.service';

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [
    BaseComponent,
    RouterLink,
    ContentCardComponent,
    SliderComponent,
    SlideComponent,
    NgIf,
    NgForOf,
    CouponComponent,
  ],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.css'
})
export class WelcomeComponent implements OnInit {

  protected readonly Array = Array;
  public campaigns!: any;
  public customer!: Customer;
  public codes!: any;

  constructor(
    public customerService:CustomerServices,
    private campaignService:CampaignsService,
    private codesService:CodesService,
    private spinnerService: NgxSpinnerService,
    private cacheService: CacheService
    ) {
  }

  ngOnInit(): void {
    this.customer = this.customerService.getCustomer();
    JsBarcode('#barcode', String(this.customer.clubId), {background: ''});

    forkJoin({
      campaigns: this.campaignService.getCampaignsGroups(),
      codes: this.codesService.getCodes(this.customer, this.customer.clubId, false)
    }).subscribe({
      next: data => {
        this.campaigns = data.campaigns;
        this.codes = data.codes;
        this.spinnerService.hide("main");
      }
    })
    let refresh = this.cacheService.get('refreshToken');
    if (!refresh) {
      this.customerService.refreshToken().subscribe({
        next: (ev: any) => {
          this.customer.storeToken(ev.token);
          this.customerService.updateToken(ev.token);
          this.cacheService.set('refreshToken', true);
        }
      });
    }

  }

  protected readonly CouponValueMode = CouponValueMode;
}
