import {Component, ElementRef, Input, OnDestroy, viewChild} from '@angular/core';
import {RouterLink} from '@angular/router';
import {NotificationsService} from '../../_services/notifications.service';
import {NotificationsResponse} from '../../_interfaces/notifications.response';
import {NgIf} from '@angular/common';
import {ThemingComponent} from '../../common/theming/theming.component';
import {NgxSpinnerComponent, NgxSpinnerModule, NgxSpinnerService} from 'ngx-spinner';
import {SwUpdate, VersionEvent, VersionReadyEvent} from '@angular/service-worker';
import {filter} from 'rxjs';
import {UpdateAppService} from '../../_services/update-app.services';

@Component({
  selector: 'app--authorized-base',
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    ThemingComponent,
    NgxSpinnerModule,
    NgxSpinnerComponent
  ],
  templateUrl: './base.component.html',
  styleUrl: './base.component.css'
})
export class BaseComponent implements OnDestroy{
  protected menuDrawer = viewChild<ElementRef>('menuDrawer');
  protected menuDrawerEl: HTMLElement | undefined;
  protected content = viewChild<ElementRef>('content');
  protected contentEl: HTMLElement | undefined;
  @Input() headerClasses = '';
  @Input() pageWrapperClasses = '';
  public data!: NotificationsResponse;
  private interval: any;
  public newVersion: boolean = false;

  constructor(
    private notificationsService: NotificationsService,
    private spinnerService: NgxSpinnerService,
    private updateService: UpdateAppService,
    ) {
  }
  refreshData(){
    this.notificationsService.fetchAll().subscribe();
  }

  ngOnInit() {
    this.spinnerService.show('main');
    this.menuDrawerEl = this.menuDrawer()!.nativeElement as HTMLElement;
    this.contentEl = this.content()!.nativeElement as HTMLElement;

    this.notificationsService.data$.subscribe(data => { // subscribe once to the data stream
      this.data = data;
    });
    this.refreshData();
    this.interval = setInterval(() => {
      this.refreshData();
    }, 10000);
    this.updateService.checkForUpdates();
  }

  toggleMenu(event: Event) {
    (event.currentTarget as HTMLElement).classList.toggle('menu-active');
    this.menuDrawerEl!.classList.toggle('translate-x-full');
    this.contentEl!.classList.toggle('opacity-0');
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
