import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, viewChild} from '@angular/core';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {BehaviorSubject, Observable, Subject, Subscriber, Subscription} from 'rxjs';
import {SubmittableComponentInterface} from '../../_interfaces/submittable.component.interface';

@Component({
  selector: 'app-pin-pad',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './pin-pad.component.html',
  styleUrl: './pin-pad.component.css'
})
export class PinPadComponent implements OnChanges {
  protected form = viewChild<ElementRef>('form');
  protected readonly Array = Array;
  public pinForm: any;
  @Input({required: true}) parentComponent!: SubmittableComponentInterface;
  @Input() fullValidation: boolean = false;
  @Output() pinValue = new EventEmitter<string>();
  @Output() error = new EventEmitter<string>();

  constructor(private formBuilder: FormBuilder) {
    this.pinForm = formBuilder.group({
      pass: formBuilder.array([
        formBuilder.control('', Validators.required),
        formBuilder.control('', Validators.required),
        formBuilder.control('', Validators.required),
        formBuilder.control('', Validators.required),
        formBuilder.control('', Validators.required),
        formBuilder.control('', Validators.required)
      ]),
    });
    this.pinForm.valueChanges.subscribe({
      next: () => {
        this.validation();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.validation();
  }

  paste(event: ClipboardEvent) {
    event.preventDefault();
    const pasteData = event.clipboardData!.getData('text').split('').slice(0, 6);
    Array(6).fill(null).forEach((i, index) => {
      const input: HTMLInputElement = document.getElementById('passcode-input-' + (index)) as HTMLInputElement;
      if (input) {
        input.focus();
        input.value = pasteData[index];
      }
    });
    this.pinForm.controls.pass.setValue(pasteData);
    const submitEvent = new SubmitEvent('submit');
    this.parentComponent.submit(submitEvent);
  }

  jumpNext($event: KeyboardEvent, $index: number) {
    const formValue = this.pinForm.controls.pass.value[$index];
    if ($event.key == formValue) {
      const passcodeInput = document.getElementById('passcode-input-' + ($index + 1));
      if (passcodeInput) {
        passcodeInput.focus();
      } else {
        const event = new SubmitEvent('submit');
        this.parentComponent.submit(event);
      }
    } else if($event.key === 'Backspace') {
      const passcodeInput = document.getElementById('passcode-input-' + ($index - 1));
      if (passcodeInput) {
        passcodeInput.focus();
      }
    }
  }

  validation() {

    let pass = this.pinForm.controls['pass'].value;
    let error = '';

    for (const passKey in pass) {
      if (isNaN(pass[passKey])) {
        error = 'Wartość w każdym polu musi być liczbą.';
      }
    }

    if (this.fullValidation) {
      for (const passKey in pass) {
        if (pass[passKey] == '') {
          error = 'Każde pole musi byc uzupełnione.';
        }
      }
    }

    let value = '';
    for (const passKey in pass) {
      if (isNaN(pass[passKey]) === false) {
        value += pass[passKey];
      }
    }
    this.pinValue.emit(value)
    this.error.emit(error)
  }
}
