import { Injectable } from '@angular/core';
import {interval} from 'rxjs';
import {SwUpdate, VersionEvent} from '@angular/service-worker';
import {CustomerServices} from './customer.services';

@Injectable({
  providedIn: 'root'
})
export class UpdateAppService {

  constructor(
    public updates: SwUpdate,
    private customerService: CustomerServices
  ) {
    if (updates.isEnabled) {
      interval(6 * 60 * 60).subscribe(() => updates.checkForUpdate()
        .then(() => {
        }));
    }
  }
  static checkIfTokenExists(){
    let token = localStorage.getItem('token') ?? '';
    return token.length > 0;
  }

  public checkForUpdates(): void {
    this.updates.versionUpdates.subscribe(event => {
      if (event.type === 'VERSION_DETECTED') {
        if (UpdateAppService.checkIfTokenExists()) {
          this.customerService.refreshToken().subscribe({
            next: (data: any) => {
              localStorage.setItem('token', data.token);
              window.location.reload();
            }
          });
        } else {
          window.location.reload();
        }
      }
    });
  }
}
