<app--authorized-base pageWrapperClasses="welcome-page">
  <app-content-card>
    <app-page-title label="Zmień dane" routerLinkUrl="/welcome" />
    <form class="grow" *ngIf="!formSuccess">
      <div class="grid md:grid-cols-2 gap-y-8 mb-20">
        <app-form-control-text-field class="w-10/12 mx-auto" [control]="f.name" name="name" label="Imię" controlError="Imię jest wymagane" />
        <app-form-control-text-field class="w-10/12 mx-auto" [control]="f.surname"  name="surname" label="Nazwisko" controlError="Nazwisko jest wymagane" />
        <app-google-places-autocomplete class="w-10/12 mx-auto" [control]="f.street" name="street" label="Ulica" [controlError]="this.streetError" (choosePlaceEvent)="setAddressFromGooglePlace($event)" />
        <app-form-control-text-field class="w-10/12 mx-auto" [control]="f.postCode"  name="postCode" label="Kod pocztowy" controlError="Kod pocztowy jest wymagany" />
        <app-form-control-text-field class="w-10/12 mx-auto" [control]="f.city"  name="city" label="Miasto" controlError="Miasto jest wymagane" />
        <app-form-control-date-field class="w-10/12 mx-auto" [control]="f.birthDate" name="birthDate" label="Data urodzenia" [controlError]="this.birthDateError" />
        <app-form-control-text-field class="w-10/12 mx-auto" [control]="f.phoneNumber" name="phoneNumber" label="Numer telefonu" controlError="Numer telefonu jest wymagany" />
        <app-form-control-text-field class="w-10/12 mx-auto" [control]="f.email" name="email" label="Adres e-mail" controlError="Adres e-mail jest wymagany" />
      </div>
      <div class="place-items-center mt-auto">
        <button (click)="onSubmit($event)" type="button" #submitButton class="btn btn-secondary w-full md:w-5/12">
          zatwierdź zmianę danych
        </button>
      </div>
    </form>
    <div class="place-items-center" *ngIf="formSuccess">
      <div><img class="brand-filter" src="/assets/images/check.svg" alt="Concierge" /></div>
      <br />
      <br />
      <h1 class="font-bold text-2xl text-gray-500 leading-relaxed">Gratulacje!</h1>
      <h2 class="leading-relaxed">Twoje dane zostały pomyślnie zmienione</h2>
      <br />
      <br />
      <div class="place-items-center w-full">
        <a routerLink="/welcome" class="md:w-6/12 btn btn-secondary w-full">wróć do strony głównej</a>
      </div>
    </div>
    <div class="flex -mb-12 gap-x-4 text-brand-dark underline">
      <a routerLink="/logout">Wyloguj się</a>
      <a routerLink="/delete-account">Usuń konto</a>
    </div>
  </app-content-card>
</app--authorized-base>
