<app--authorized-base>
  <app-content-card>
    <app-page-title label="Usuń konto" routerLinkUrl="/welcome" />
    <div class="text-gray-dark">
      <span>Przykro nam, że chcesz usunąć swoje konto.</span><br>
      <span>Mamy nadzieję, że wrócisz do nas w przyszłości.</span>
      <br>
      <br>
      <p class="mb-12">Dziękujemy za dotychczasowe zaufanie!</p>
    </div>
    <form (submit)="submit($event)">
      <button *ngIf="!this.tokenSending" (click)="this.sendpin()" type="button" class="btn btn-secondary mx-auto px-20 btn-text-shadow">Wyślij pin</button>
      <div *ngIf="this.tokenSending" id="page-wrapper" class="flex items-center flex-col">
        <h1 class="text-2xl font-bold mb-0.5">Wpisz kod potwierdzający</h1>
        <h2 class="text-gray-light">Wysłaliśmy Tobie mailem lub sms-em jednorazowy kod potwierdzający. Przepisz go poniżej.</h2>
        <app-pin-pad [parentComponent]="this" [fullValidation]="this.fullValidation" (pinValue)="this.pinPadValue = $event" (error)="this.pinPadError = $event"/>
        <small *ngIf="this.pinPadError !== ''" class="text-red-500">{{ this.pinPadError }}</small>
        <button #submitButton type="submit" class="btn btn-secondary mx-auto px-20 btn-text-shadow mt-32">Usuń konto</button>
      </div>
    </form>
  </app-content-card>
</app--authorized-base>
