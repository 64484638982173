<google-map (mapInitialized)="openNearest()" [center]="center" [zoom]="zoom" height="500px" width="100%">
  <map-marker
    *ngFor="let marker of markers"
    #mapMarker="mapMarker"
    (markerInitialized)="addAnchor($event)"
    [position]="marker.position"
    [label]="marker.label"
    [title]="marker.title"
    [options]="marker.options"
    (mapClick)="openInfoWindow(marker.shop, mapMarker)"
  ></map-marker>
  <map-info-window>
    {{this.selectedShop()?.name}}<br/>
    {{this.selectedShop()?.zip}} {{this.selectedShop()?.city}}
  </map-info-window>
</google-map>
