import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {RouterLink} from '@angular/router';
import {PageTitleComponent} from '../page-title/page-title.component';
import {CompanyServices} from '../../_services/company.services';
import {CompaniesResponse} from '../../_interfaces/companies.response.interface';
import {NgOptimizedImage} from '@angular/common';
import {ContentCardComponent} from '../../common/content-card/content-card.component';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-brands',
  standalone: true,
  imports: [
    BaseComponent,
    RouterLink,
    PageTitleComponent,
    NgOptimizedImage,
    ContentCardComponent
  ],
  templateUrl: './brands.component.html',
  styleUrl: './brands.component.css'
})
export class BrandsComponent implements OnInit {
  public companies: CompaniesResponse[];

  constructor(private companyService: CompanyServices, private spinnerService: NgxSpinnerService) {
    this.companies = [];
  }

  protected readonly Array = Array;

  ngOnInit(): void {
      this.companyService.getCompanies(true).subscribe({
        next: data => {
          this.companies = data;
          this.spinnerService.hide('main');
        }
      });
  }
}
