import { Component } from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ContentCardComponent} from '../../../common/content-card/content-card.component';
import {PageTitleComponent} from '../../page-title/page-title.component';
import {ActivatedRoute} from '@angular/router';
import {InvoicesService} from '../../../_services/invoices.service';
import {InvoicesResponse} from '../../../_interfaces/invoices.response';
import moment from 'moment';
import {CampaignsService} from '../../../_services/campaigns.service';
import {NgIf} from '@angular/common';
import {Campaign} from '../../../_interfaces/campaign.interface';
import {CodesService} from '../../../_services/codes.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [
    BaseComponent,
    ContentCardComponent,
    PageTitleComponent,
    NgIf
  ],
  templateUrl: './order.component.html',
  styleUrl: './order.component.css'
})
export class OrderComponent {

  protected invoice: InvoicesResponse|null = null;
  protected showInvoice = true;
  public quoteSum: number = 0;
  public usedCampaign: boolean = false;
  public campaignList: Array<Campaign> = [];
  public quoteDiscountSum: number = 0;

  constructor(
    private route: ActivatedRoute,
    private invoiceService: InvoicesService,
    private campaignService: CampaignsService,
    private codeServices: CodesService,
    private spinnerService: NgxSpinnerService
  ) {
  }

  ngOnInit() {
    const invoiceId = this.route.snapshot.queryParams['invoiceId'];
    this.invoiceService.getInvoice(invoiceId).subscribe({
      next: (invoice) => {
        this.invoice = invoice;
        for (const invoiceElement of invoice.invoiceElements) {
          this.quoteSum = this.quoteSum + parseFloat(invoiceElement.quoteDiscount);
        }
        this.quoteSum = Math.round(this.quoteSum * 100) / 100;
        this.quoteDiscountSum = Math.round((this.invoice.quote - this.quoteSum) * 100) / 100;
        if (this.invoice.saleAction) {
          for (const campaign of this.invoice.saleAction.data.campaigns) {
            this.campaignService.getCampaign(campaign).subscribe({
              next: (campaign) => {
                this.campaignList.push(campaign);
                this.usedCampaign = true;
              }
            })
          }
          for (const coupons of this.invoice.saleAction.data.coupons) {
            this.codeServices.getCode(coupons).subscribe({
              next: (campaign: any) => {
                this.campaignList.push(campaign.campaignCompany.campaign);
                this.usedCampaign = true;
              }
            })
          }
        }
        this.spinnerService.hide('main');
      },
      error: (error) => {this.showInvoice = false}
    })
  }

  protected readonly moment = moment;
}
