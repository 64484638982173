<app--authorized-base pageWrapperClasses="welcome-page" class="brand-qsq">
  <app-content-card>
    <app-page-title label="Zgody" routerLinkUrl="/welcome" />
    <div class="flex h-96 flex-col" *ngIf="formSubmitted === false">
      <form (submit)="onSubmit($event)" class="flex flex-col gap-y-6 grow">
        <div *ngIf="this.consents">
          <div *ngFor="let choice of this.consents; let i=index" class="my-2">
            <label class="w-full flex items-start gap-x-3">
              @if (this.checked[choice.id] && this.checked[choice.id] == true ) {
                <span class="opacity-40 w-6 h-6 border-2 text-gray-light shrink-0 rounded border-brand-light bg-brand-light">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fill-white">
                    <path d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" />
                </svg>
                </span>
              } @else {
                <span class="opacity-40 w-6 h-6 border-2 text-gray-light shrink-0 rounded border-gray-light cursor-pointer"></span>
              }
              <input readonly class="absolute opacity-0 h-0 w-0" type="checkbox" [value]="choice.id">
              <span class="text-gray-light text-sm" [innerHtml]="choice.content"></span>
            </label>
          </div>
        </div>
      </form>
    </div>
    <div class="flex h-96 flex-col" *ngIf="formSubmitted">
      <div class="place-items-center"><img class="brand-filter" src="/assets/images/check.svg" alt="Concierge" /></div>
      <div class="place-items-center mt-10 mb-20 grow flex flex-col">
        <h1 class="text-gray-500 font-bold text-2xl">Gratulacje!</h1>
        <p class="text-gray-light font-light leading-relaxed">Twoje dane zostały pomyślnie zmienione</p>
      </div>
      <div class="place-items-center justify-end">
        <a routerLink="/welcome" class="md:w-6/12 btn btn-secondary w-full">wróć do strony głównej</a>
      </div>
    </div>
  </app-content-card>
</app--authorized-base>
