<app--authorized-base pageWrapperClasses="welcome-page" class="brand-qsq">
  <app-content-card>
    <app-page-title label="Poznaj nasze marki" routerLinkUrl="/welcome" />
    <div class="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-7">
      @for (i of companies; track $index) {
        <a routerLink="brand-page" [queryParams]="{name: i.name}" class="flex h-[120px] items-center justify-center bg-white shadow-[0px_0px_25px_#0000001A] rounded-2xl">
          <img src="/assets/{{i.logo.filePath}}" alt="Logo {{i.logo.filePath}}"/>
        </a>
      }
    </div>
  </app-content-card>
</app--authorized-base>
