<div class="fixed inset-0 z-50 overflow-y-auto">
<button
  type="button"
  mat-stroked-button
  color="primary"
  (click)="CloseDialog()"
  class="float-right"
>
  Zamknij
</button>
<iframe
  [src]="urlMap"
  width="100%"
  [height]="height"
  frameborder="0"
  style="border:0"
  allowfullscreen
></iframe>
<div mat-dialog-actions>

</div>
</div>
