import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogRef} from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-iframe-dialog',
  templateUrl: './iframe-dialog.component.html',
  styleUrls: ['./iframe-dialog.component.css'],
  imports: [
    MatDialogActions,
    MatButtonModule
  ],
  standalone: true
})
export class IframeDialogComponent implements OnInit {
  url: string = 'https://example.com/';
  urlMap: SafeResourceUrl | undefined;
  tabIndex: number = 0;
  constructor(
    private _mdr: MatDialogRef<IframeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    public sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {
    this.url = data?.url;
  }
  CloseDialog() {
    this._mdr.close();
  }

  ngOnInit() {
    this.urlMap = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.height = window?.innerHeight;
  }

  protected readonly window = window;
  height: any;
}
