import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.css'
})
export class CheckboxComponent {
  @Input({required: true}) name = '';
  @Input() required = false;
  @Output() inputChanged = new EventEmitter<boolean>();
  protected checked = false;

  protected change(event: Event) {
    this.checked = (event.target as HTMLInputElement).checked;
    this.inputChanged.emit(this.checked);
  }
}
