<app--unauthorized-base>
  <h1 class="text-2xl font-bold mb-0.5">Załóż konto</h1>
  <h2 class="text-gray-light">Podaj swój adres e-mail</h2>

  <form novalidate [formGroup]="this.registerForm" (submit)="submit($event)" class="grow flex flex-col">
    <div class="mt-8 grid md:grid-cols-2 gap-y-8">
      <app-form-control-text-field class="w-10/12" name="first-name" [control]="registerForm.controls['name']" label="Imię" controlError="Imię jest wymagane" />
      <app-form-control-text-field class="w-10/12" name="last-name" [control]="registerForm.controls['surname']" label="Nazwisko" controlError="Nazwisko jest wymagane" />
      <app-form-control-text-field class="w-10/12" name="email" [control]="registerForm.controls['email']" label="Adres e-mail" controlError="Wymagany jest poprawny adres email" />
    </div>
    <div *ngIf="this.consents" class="mt-8">
      <div *ngFor="let choice of this.consents; let i=index" class="mt-4">
          <app-checkbox (inputChanged)="consentChange($event, choice.id)" [required]="true" name="{{choice.id}}">
            <div [innerHTML]="choice.content"></div>
          </app-checkbox>
        <small *ngIf="submitted && this.checked[choice.id] !== true" class="text-red-500">Ta zgoda jest wymagana</small>
      </div>
    </div>
    <br /><br />
    <app-form-alert text="Przepraszamy, ale nie możemy założyć konta na podany numer telefonu." *ngIf="registerFormError"></app-form-alert>
    <button #submitButton type="submit" class="btn btn-secondary btn-text-shadow mt-auto w-full">dalej</button>
  </form>
  <ngx-spinner name="main" [fullScreen]="true" type="ball-spin"/>
</app--unauthorized-base>
