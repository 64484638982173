<app--authorized-base>
  <app-content-card>
    <app-page-title label="Numer zakupu {{invoice?.number}}" routerLinkUrl="/orders" />
    @if (invoice && showInvoice) {
      <div class="text-gray-dark mx-2">
        <p class="-mt-8">Data zakupu {{moment(invoice.sellDate).format("DD.MM.YYYY")}}</p>
        <br>
        <p class="font-semibold text-lg mb-3">Kupione produkty</p>
        <ol class="list-decimal">
          @for(element of invoice.invoiceElements; track $index) {
            <li class="ml-3 mb-3">
              <div class="flex justify-between">
                <div>
                  <span>{{element.name}}</span><br>
                  <span>SKU: {{element.sku}}</span>
<!--                  <span>Rozmiar: &lt;rozmiar&gt;</span>-->
                </div>
                <div>
                  <span>Ilość: {{element.quantity}}</span><br>
                  <span>{{element.quote}} zł</span>
                </div>
              </div>
            </li>
          }
        </ol>
        <br />
        <div class="">
          <div class="float-left">
            <div *ngIf="usedCampaign"></div>
            @for(element of this.campaignList; track $index) {
            <div  class="rounded-full bg-brand-medium text-white py-5 px-10">
              Rabat -{{element.percent}} % {{element.shortText}} <br />
              z klubem oszczędzasz {{this.quoteDiscountSum}} zł
            </div>
              <br />
            }
          </div>
          <div class="float-right text-right">
            <p class="font-semibold text-lg">Łączna kwota {{this.quoteSum}} zł</p>
            <p class="font-semibold text-lg">Cena po rabacie {{invoice.quote}} zł</p>
            <p class="">w tym VAT</p>
          </div>
        </div>
      </div>
    } @else if (!showInvoice) {
      <div class="text-gray-dark text-2xl font-bold my-44 text-center">
        Nie znaleziono zakupu o podanym numerze
      </div>
    }
  </app-content-card>
</app--authorized-base>
