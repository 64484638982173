import {UserTokenInterface} from '../_interfaces/user-token.interface';
import {environment} from '../../environments/environment';

export class User implements UserTokenInterface {
  getApiUrl(): string {
    return environment.apiHost + '/users';
  }
  public email: string = '';

  storeToken(token:string){
    localStorage.setItem('user_token', token);
  }

  getToken() {
    return localStorage.getItem('user_token') ?? null;
  }
}
